import React, { useState } from 'react';
import 'react-slideshow-image/dist/styles.css';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import MADHU_SAJJAN from '../../../assets/img/Toppers/MADHU_SAJJAN.JPG';
import AVANTIKA from '../../../assets/img/Toppers/AVANTIKA.JPG';
import SMEETA_ARALIKATTI from '../../../assets/img/Toppers/SMEETA_ARALIKATTI.JPG';
import BASAVARAJ_MULWAD_SCIENCE from '../../../assets/img/Toppers/BASAVARAJ_MULWAD_SCIENCE.JPG';
import NIHARIKA from '../../../assets/img/Toppers/NIHARIKA.JPG';
import RAKSHITA_KAI_SCIENCE from '../../../assets/img/Toppers/RAKSHITA_KAI_SCIENCE.JPG';
import SPOORTHI_MUDNOOR_SICENCE from '../../../assets/img/Toppers/SPOORTHI_MUDNOOR_SICENCE.JPG';
import YASH_R_KALYANSHETTY from '../../../assets/img/Toppers/YASH_R_KALYANSHETTY.JPG';
import SUNIL  from '../../../images/01-SUNIL K SHIVANAGI.jpg';
import PRAJWAL from '../../../images/02-PRAJWAL S BANDI.jpg';
import JYOTI  from '../../../images/03-JYOTI MANANKALAGI.jpg';
import RAKESH from '../../../images/04-RAKESH ARAVIND.jpg';
import ADITYA from '../../../images/05-ADITYA TALAWAR.jpg';



const TopRankers = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 1200, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <div>
      <div className="container">
        <header class="toppers-header">
          <h2>Toppers</h2>
        </header>
        <Carousel responsive={responsive}>
          <div style={{paddingLeft:"40px"}}>
            {' '}
            <img
              src={SUNIL}
              class="img-fluid"
              alt=""
              style={{ width: 300, height: 300 , paddingRight:20}}
            />
            <div class="member-info">
              <h4>SUNIL K SHIVANAGI</h4>
            </div>
          </div>
          <div style={{paddingLeft:"40px"}}>
            {' '}
            <img
              src={PRAJWAL}
              class="img-fluid"
              alt=""
              style={{ width: 300, height: 300, paddingRight:20 }}
            />
            <div class="member-info">
              <h4>PRAJWAL S BANDI</h4>
            </div>
          </div>
          <div style={{paddingLeft:"40px"}}>
            {' '}
            <img
              src={JYOTI }
              class="img-fluid"
              alt=""
              style={{ width: 300, height: 300 , paddingRight:20}}
            />
            <div class="member-info">
              <h4>JYOTI MANANKALAGI</h4>
            </div>
          </div>
          <div style={{paddingLeft:"40px"}}>
            {' '}
            <img
              src={RAKESH}
              class="img-fluid"
              alt=""
              style={{ width: 300, height: 300 , paddingRight:20}}
            />
            <div class="member-info">
              <h4>RAKESH ARAVIND</h4>
            </div>
          </div>
          <div style={{paddingLeft:"40px"}}>
            {' '}
            <img
              src={ADITYA}
              class="img-fluid"
              alt=""
              style={{ width: 300, height: 300 , paddingRight:20}}
            />
            <div class="member-info">
              <h4>ADITYA TALAWAR</h4>
            </div>
          </div>

          <div style={{paddingLeft:"40px"}}>
            {' '}
            <img
              src={SUNIL}
              class="img-fluid"
              alt=""
              style={{ width: 300, height: 300, paddingRight:20 }}
            />
            <div class="member-info">
              <h4>SUNIL K SHIVANAGI</h4>
            </div>
          </div>
          <div style={{paddingLeft:"40px"}}>
            {' '}
            <img
              src={PRAJWAL}
              class="img-fluid"
              alt=""
              style={{ width: 300, height: 300 , paddingRight:20}}
            />
            <div class="member-info">
              <h4>PRAJWAL S BANDI</h4>
            </div>
          </div>
          <div style={{paddingLeft:"40px"}}>
            {' '}
            <img
              src={JYOTI}
              class="img-fluid"
              alt=""
              style={{ width: 300, height: 300, paddingRight:20 }}
            />
            <div class="member-info">
              <h4>JYOTI MANANKALAGI</h4>
            </div>
          </div>
        </Carousel>
      </div>
    </div>
  );
};

export default TopRankers;
