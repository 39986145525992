import Recat from "react";
import { Link } from "react-router-dom";
import DR_RABINCHANDRA from "../../../assets/img/Staffs/ScienceStaffs/DR_RABINCHANDRA.JPG";
import Floats from "../../home/home components/Floats";
import Footer from "../../home/home components/Footer";
import NavBar from "../../home/home components/NavBar";
import principal from '../../../assets/img/principal.jpg'

const PrincipalMessage = () => {
  return (
    <div>
      <NavBar />
      <section id="breadcrumbs" class="breadcrumbs">
        <div class="container">
          <ol>
            <li>
              <Link to={"/"}>Home</Link>
            </li>
            <li>About Us</li>
          </ol>
          <h2>Principal’s Message</h2>
        </div>
      </section>
      {/* <!-- End Breadcrumbs --> */}
      <section id="Staffs" class="Staffs">
        <div class="row ">
          <div class="col-lg-4">
            <div class="post-box">
              <div class="post-img">
                <img src={principal} class="img-fluid center" alt="" />
              </div>
              <h5 class="post-title">Prof. Hemant Krishna Udupi</h5>
              <span class="post-date">
                <strong>
                  Principal 
                  {/* <br />
                  M.Sc.,B.Ed.,M.Ed.,M.Phil.,Ph.D.,F.S.Sc.,F.S.E.Sc.,F.I.S.E.C.,F.I.S.B.
                  <br />
                  T.,F.I.S.C.A.,D.Litt. (USA).,(D.Sc).,PIMR&LW */}
                </strong>
              </span>
            </div>
          </div>

          <div class="col-lg-8 ">
            <div class="post-box">
              <h5 class="post-title" >Principal’s Message:</h5>
              Greetings of the day!
              
            <p>PU is the decisive stage that determines the future career, happiness, and life as a whole. Hence the institutional role becomes pivotal along with that of parental in moulding the adolescents, chiselling their path so as to ensure their remaining focused and never getting swerved from the path.</p>
            <p >I deem it my fortune to head this prestigious institution once again (after 2006-2011). Honestly inculcating the right values and aptitude among students, extracting 100% both from the students and staff through judicious planning and meticulous execution, striking a balance between the Board and competitive exams - is my priority right now. Blessed to have 35 years’ Academic and Administrative experience to reckon with Premier Institutions like Manipal Academy, Manipal, Alva’s Education Foundation, Moodbidri, and K.L.E.Society, Belagavi.</p> 
              <p >Looking forward to a synergetic association with a bonding of care and share.</p>
                      <br/>Warm Regards,<br/>

                      <br/>Prof. Hemant Krishna Udupi<br/>
                             (Director Principal)

              <br />
              <br />
              {/* <p className="text-muted-quote">
                <strong>
                  {" "}
                  “ Education is the most powerful weapon which you can use to
                  change the World ” - Nelson Mandela.
                </strong>
                <br />
                <strong>
                  {" "}
                  “ Where Dreams take wings and aspirations are elevated to a
                  status of reality ”
                </strong>
                <br />
                <strong>
                  {" "}
                  " Transforming ransforming the insitutional mission to the
                  mission / fruit of success."
                </strong>
                <br />
              </p> */}
            </div>
          </div>
        </div>
      </section>

      {/* <section id="Staffs" class="Staffs">
        <div class="row ">
          <div class="col-lg-12">
            <div class="post-box">
              I developed a passion to serve the society through teaching
              profession, providing quality education and the importance given
              to the character building in children. To Strive for excellence in
              my job and actively participate in the organization growth, in
              keeping up your highly esteemed institutional motto and
              objectives. <br /> <br />
              I extend a warm welcome to all the young aspirants to our College
              who are in pursuit of value added education, benefits and memories
              herewith, I am sure you will cherish all the days of your life. We
              believe in providing you with a learning ambience that will
              translate your dreams into a measurable action.
              <br />
              <br />
              The rich array of academic, co-curricular and extra-curricular
              programs we offer comes with the mission of contributing to the
              holistic development of your personality. We accept that the right
              value based education can shape each student into an agent of
              positive change and builders of a society. With the collective
              efforts of the students, staff and parents and our esteemed
              management, we are certain that we will steadily achieve the
              cherished goals. Our educators are highly committed to the ethos
              of the PU College. They focus on innovation and Endeavour to
              discover the potential of each student ensuring that they learn
              and grow in a pleasant and friendly environment to access success
              and excellence. We strongly believe that academic success is only
              part of the route towards university and employment success,
              education in the true sense means enjoying learning, thinking, and
              knowing. Hence, we encourage our students to contribute to the
              community, society and country by inculcating values and
              attributes such as tolerance, empathy, respect for others and
              open- mindedness.
              <br />
              <br />
              At our college we pursue our passion and strive for excellence by
              creating and providing a unique learning culture by focusing on
              curriculum and extracurricular activities to bring out the best in
              each child. A teacher must himself have a dream. His own life must
              be fired that dream. His own life must be the actualization of
              that dream. The details of the dream may vary, but its crux must
              be constant self – improvement. Such a teacher aims to understand
              himself by teaching his students. It is the intensity of our dream
              that lights the fire in our students. For as Swami Vivekananda
              famously said, "Be and make”. Let us all, therefore, prepare
              ourselves accordingly.
              <br />
              <br />
              <strong>
                Thanking each one of you with a positive vibe in my mind.
                <br />
                Have a serene and ecstatic time ahead.
              </strong>
            </div>
          </div>
        </div>
      </section> */}
      <Footer />
      <Floats />
    </div>
  );
};

export default PrincipalMessage;
