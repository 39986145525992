import React, { useEffect, useState } from "react";
import './Navbar.css';
import logo_header from "../../../assets/img/logo_header.png";
import { Link } from "react-router-dom";

const NavBar = () => {
  let hash = "click";

  const select = (el, all = false) => {
    el = el.trim();
    if (all) {
      return [...document.querySelectorAll(el)];
    } else {
      return document.querySelector(el);
    }
  };

  const on = (type, el, listener, all = false) => {
    if (all) {
      select(el, all).forEach((e) => e.addEventListener(type, listener));
    } else {
      select(el, all).addEventListener(type, listener);
    }
  };

  useEffect(() => {
    const scrollto = (el) => {
      let header = select("#header");
      let offset = header.offsetHeight;

      if (!header.classList.contains("header-scrolled")) {
        offset -= 10;
      }

      let elementPos = select(el).offsetTop;
      window.scrollTo({
        top: elementPos - offset,
        behavior: "smooth",
      });
    };

    const onscroll = (el, listener) => {
      el.addEventListener("scroll", listener);
    };

    on("click", ".mobile-nav-toggle", function (e) {
      select("#navbar").classList.toggle("navbar-mobile");
      this.classList.toggle("bi-list");
      this.classList.toggle("bi-x");
    });
    on(
      "click",
      ".navbar .dropdown > a",
      function (e) {
        if (select("#navbar").classList.contains("navbar-mobile")) {
          e.preventDefault();
          this.nextElementSibling.classList.toggle("dropdown-active");
        }
      },
      true
    );
    on(
      "click",
      ".scrollto",
      function (e) {
        if (select(hash)) {
          e.preventDefault();

          let navbar = select("#navbar");
          if (navbar.classList.contains("navbar-mobile")) {
            navbar.classList.remove("navbar-mobile");
            let navbarToggle = select(".mobile-nav-toggle");
            navbarToggle.classList.toggle("bi-list");
            navbarToggle.classList.toggle("bi-x");
          }
          scrollto(this.hash);
        }
      },
      true
    );
    // console.log(toggleSidebar);
  });

  /**
   * Mobile nav toggle
   */

  /**
   * Mobile nav dropdowns activate
   */

  return (
    <div>
      <header id="header" class="header fixed-top header-scrolled">
        <div class="container-fluid container-xl d-flex align-items-center justify-content-between">
          <Link to="/" class="logo d-flex align-items-center">
            <img src={logo_header} alt="" />
            <span>SRPU College</span>
          </Link>

          <nav id="navbar" class="navbar">
            <ul>
              <li>
                <Link to="/" class="nav-link scrollto active">
                  Home
                </Link>
              </li>
              <li class="dropdown">
                <Link to="">
                  <span>About Us</span> <i class="bi bi-chevron-down"></i>
                </Link>
                <ul>
                  <li>
                    <Link to="/swami">
                      Shri Sanganabasava Shivayogi of Banthanal
                    </Link>
                  </li>
                  <li>
                    <Link to="/college">About SRPU College</Link>
                  </li>
                  <li>
                    <Link to="/vissionandmission">Vision and Mission</Link>
                  </li>
                  <li>
                    <Link to="/presidentMessage">President’s message</Link>
                  </li>
                  <li>
                    <Link to="/chairmanmessage">Chairman’s message</Link>
                  </li>
                  <li>
                    <Link to="/caomessage">CAO’s message</Link>
                  </li>
                  <li>
                    <Link to="/boardofdirectors">Board of Directors</Link>
                  </li>
                  <li>
                    <Link  to="/principalmessage">Principal’s message</Link>
                  </li>

                  <li class="dropdown">
                    <Link to="">
                      <span>Sister Concerns</span>{" "}
                      <i class="bi bi-chevron-right"></i>
                    </Link>
                    <ul>
                      <li>
                        <Link to="https://sirs.ac.in">
                          Sanganabasava International Residential School
                        </Link>
                      </li>
                      <li>
                        <Link to="">
                          S.C.Uppin College of Commerce and Management
                        </Link>
                      </li>
                      <li>
                        <Link to="https://sbsn.ac.in">
                          Sanganabasava Shishuniketan School , Vijayapur
                        </Link>
                      </li>
                      <li>
                        <Link to="">Nilakanteshwar Vidya Mandir</Link>
                      </li>
                      <li>
                        <Link to="">Atal Bihari Vajpayee School</Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="">Student councils</Link>
                  </li>
                </ul>
              </li>
              <li class="dropdown">
                <Link to="">
                  <span>Streams</span> <i class="bi bi-chevron-down"></i>
                </Link>
                <ul>
                  {/* <li>
                    <Link to="/sciencestaffs">Science Stream Staff</Link>
                  </li> */}
                  <li>
                    <Link to="/commercestaffs">Commerce Stream Staff</Link>
                  </li>
                </ul>
              </li>

              <li class="dropdown">
                <Link to="">
                  <span>Infrastructure</span> <i class="bi bi-chevron-down"></i>
                </Link>
                <ul>
                  <li>
                    <Link to="/laboratories">Laboratories</Link>
                  </li>
                  <li>
                    <Link to="/Sports">Sports</Link>
                  </li>
                  <li>
                    <Link to="/Hostel">Hostel</Link>
                  </li>
                  <li>
                    <Link to="/Libraries">Libraries</Link>
                  </li>
                  <li>
                    <Link to="/Annapoorna">Annapoorna</Link>
                  </li>
                </ul>
              </li>

              <li class="dropdown">
                <Link to="">
                  <span>Media</span> <i class="bi bi-chevron-down"></i>
                </Link>
                <ul>
                  <li>
                    <Link to="/gallery">Gallery</Link>
                  </li>
                </ul>
              </li>

              <li>
                <Link class="nav-link scrollto" to="/notification">
                  Notification
                </Link>
              </li>
              
              <li>
              {/* <span className="blink"> */}
                <Link class="nav-link scrollto blink" to="/Result" >
                  Results
                </Link>
                
              </li>
              
              
              <li>
                <Link class="nav-link scrollto" to="/contact">
                  Contact
                </Link>
              </li>
            </ul>
            <i class="bi bi-list mobile-nav-toggle"></i>
          </nav>
        </div>
      </header>
    </div>
  );
};

export default NavBar;
