import React from "react";
import { Link } from "react-router-dom";
import { Card, CardHeader } from "react-bootstrap";
import Floats from "../../home/home components/Floats";
import Footer from "../../home/home components/Footer";
import NavBar from "../../home/home components/NavBar";
import sports from "../../../assets/img/Infrastructure/sports.JPG";
import sports1 from "../../../assets/img/Infrastructure/sports1.JPG";

const Sports = () => {
  return (
    <div>
      <NavBar />
      {/* <!------- Breadcrumbs Section -------> */}
      <section id="breadcrumbs" class="breadcrumbs">
        <div class="container">
          <ol>
            <li>
              <Link to={"/"}>Home</Link>
            </li>
            <li>Infrastructure</li>
          </ol>
          <h2>SPORTS</h2>
        </div>
      </section>
      {/* <!------- End of Breadcrumbs Section -------> */}

      {/* <!-------Infrastructure Sports Section -------> */}
      <section id="Infrastructure" class="Infrastructure">
        <div class="container">
          <div class="row">
            <header class="section-header">
            <Card className="text-center">
                <Card.Header><h4>PLAYGROUND AND SPORTS</h4></Card.Header>  
              </Card>
            </header>
            <p>
              SRPU college ensures a healthy holistic life style within the student community by encouraging then to participate in sports. To facilitate physical education and sporting activities, expert coaching is provided for a variety of games like throw ball, basket ball and badminton: indoor games like chess, carom, and table tennis are also popular among student.
            </p>
              <div class="col-lg-6">
                <div class="post-box">
                  <div class="post-img">
                    <img src={sports} class="img-fluid center" alt="" />
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="post-box">
                  <div class="post-img">
                    <img src={sports1} class="img-fluid center" alt="" />
                  </div>
                </div>
              </div>
          </div>
        </div>
      </section>
      {/* <!------- End of Infrastructure Sports Section -------> */}

      <Footer />
      <Floats />
    </div>
  );
};
export default Sports;
