import React from "react";
import { Link } from "react-router-dom";
import { Card, CardHeader } from "react-bootstrap";
import Floats from "../../home/home components/Floats";
import Footer from "../../home/home components/Footer";
import NavBar from "../../home/home components/NavBar";
import dining1 from "../../../assets/img/Infrastructure/dining1.JPG";
import dining2 from "../../../assets/img/Infrastructure/dining2.JPG";
import dining3 from "../../../assets/img/Infrastructure/dining3.JPG";
import dining4 from "../../../assets/img/Infrastructure/dining4.JPG";
import Buildings_Hostel1 from "../../../assets/img/Infrastructure/Buildings_Hostel1.JPG";
import Buildings_Hostel2 from "../../../assets/img/Infrastructure/Buildings_Hostel2.JPG";
import Buildings_Hostel3 from "../../../assets/img/Infrastructure/Buildings_Hostel3.JPG";
import Buildings_Dinning_Hall from "../../../assets/img/Infrastructure/Buildings_Dinning_Hall.JPG";

const Hostel = () => {
  return (
    <div>
      <NavBar />
      {/* <!------- End of Breadcrumbs Section -------> */}
      <section id="breadcrumbs" class="breadcrumbs">
        <div class="container">
          <ol>
            <li>
              <Link to={"/"}>Home</Link>
            </li>
            <li>Infrastructure</li>
          </ol>
          <h2>HOSTEL</h2>
        </div>
      </section>
      {/* <!------- End of Breadcrumbs Section -------> */}

      {/* <!-------Infrastructure Hostel Section -------> */}
      <section id="Infrastructure" class="Infrastructure">
        <div class="container">
          <div class="row">
            <header class="section-header">
              <Card className="text-center">
                <Card.Header>
                  <h4>RESIDENTIAL FACILITIES</h4>{" "}
                </Card.Header>
              </Card>
            </header>
            <p>
              SRPU College of science and commerce provides accommodation to all
              students which include their food, laundry, recreation, health
              care and other grooming support. The rooms are spacious and house
              4 to 6 students in each room. Hostel parents and the resident
              teachers are overall responsible for the students’ safety,
              guidance and care. Hygienic kitchens, supervision, laundry,
              medical checks ups, exercise and fitness are just some of the
              amenities. Each room is furnished with individual beds, table and
              individual storage space for clothing. Housekeeping is taken care
              of and the rooms are maintained neat and tidy.
            </p>
            <div class="col-lg-6">
              <div class="post-box">
                <div class="post-img">
                  <img
                    src={Buildings_Hostel1}
                    class="img-fluid center"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="post-box">
                <div class="post-img">
                  <img
                    src={Buildings_Hostel2}
                    class="img-fluid center"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="post-box">
                <div class="post-img">
                  <img
                    src={Buildings_Hostel3}
                    class="img-fluid center"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
      <Floats />
    </div>
  );
};
export default Hostel;
