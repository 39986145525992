import React, { useState } from "react";
import PortfolioDetails from "./PortfolioDetails";

const Portfolio = () => {
  const [items, setItems] = useState(PortfolioDetails);

  const filterItem = (categoryitem) => {
    const updateItem = PortfolioDetails.filter((currentItem) => {
      return currentItem.category === categoryitem;
    });
    setItems(updateItem);
  };
  return (
    <div>
      <section id="portfolio" class="portfolio">
        <div class="container">
          <header class="section-header">
            <h2>PORTFOLIO</h2>
          </header>

          <div class="row">
            <div class="col-lg-12 d-flex justify-content-center">
              <ul id="portfolio-flters">
                <li data-filter="*" class="filter-active">
                  All
                </li>
                <li
                  data-filter=".filter-app"
                  onClick={() => {
                    filterItem("Laboratories");
                  }}
                >
                  Laboratories
                </li>
                <li
                  data-filter=".filter-card"
                  onClick={() => {
                    filterItem("Sports");
                  }}
                >
                  Sports
                </li>
                <li
                  data-filter=".filter-web"
                  onClick={() => {
                    filterItem("Hostel");
                  }}
                >
                  Hostel
                </li>
                <li
                  data-filter=".filter-web"
                  onClick={() => {
                    filterItem("Libraries");
                  }}
                >
                  Libraries
                </li>
                <li
                  data-filter=".filter-web"
                  onClick={() => {
                    filterItem("Annapoorna");
                  }}
                >
                  Annapoorna
                </li>
              </ul>
            </div>
          </div>

          <div class="row gy-4 portfolio-container">
            {items.map((item, index) => {
              return (
                <div class="col-lg-4 col-md-6 portfolio-item filter-app">
                  <div class="portfolio-wrap">
                    <img src={item.url} class="img-fluid" alt="bio" />
                    <div class="portfolio-info">
                      <h4>{item.name}</h4>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Portfolio;
