import React from "react";
import { Link } from "react-router-dom";
import CountUp from "react-countup";

const AboutHome = () => {
  return (
    <div>
      <section id="counts" class="counts">
        <div class="container">
          <div class="row gt-4">
            <div class="col-lg-3 col-md-6">
              <div class="count-box ">
                <i class="fas fa-user-friends"></i>
                <div>
                  <CountUp
                    className="counter"
                    start={0}
                    end={521}
                    duration={1}
                  />
                  <p>Students</p>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-md-6">
              <div class="count-box">
                <i class="fas fa-chalkboard-teacher"></i>
                <div>
                  <CountUp
                    className="counter"
                    start={0}
                    end={36}
                    duration={1}
                  />
                  <p>Staffs</p>
                </div>
              </div>
            </div>
              
            <div class="col-lg-3 col-md-6">
              <div class="count-box">
                <i class="fas fa-hands-helping"></i>
                <div>
                  <CountUp className="counter" start={0} end={2} duration={1} />
                  <p>Streams</p>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-6">
              <div class="count-box">
                <i class="fab fa-readme"></i>
                <div>
                  <CountUp className="counter" start={0} end={4} duration={1} />
                  <p>Courses</p>
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </section>

      <section id="about" class="about">
        <div class="container">
          <div class="row gx-0">
            <div class="col-lg-6 d-flex flex-column justify-content-center">
              <div class="content">
                <h3>Why you choose us ?</h3>
                <p>
                  Sanganabasava Residential PU college has a rich academic
                  tradition of 17 years and unique ethos that is held in high
                  esteem by all.Here at SRPU, we combine the best of traditional
                  values and educational practices with the most upto date
                  teaching techniques, technology, and resources for highly
                  effective and enjoyable teaching and learning.
                </p>
                <div class="text-center text-lg-start">
                  <Link
                    to="/about"
                    class="btn-read-more d-inline-flex align-items-center justify-content-center align-self-center"
                  >
                    <span>Read More</span>
                    <i class="bi bi-arrow-right"></i>
                  </Link>
                </div>
              </div>
            </div>
            <div class="col-lg-6 d-flex align-items-center">
              <iframe
                width="800"
                height="500"
                src="https://www.youtube.com/embed/lDAMyKirea8"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AboutHome;
