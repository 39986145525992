import React from "react";
import { Link } from "react-router-dom";
import college from "../../assets/img/Infrastructure/college.jpg";
import Footer from "../home/home components/Footer";
import NavBar from "../home/home components/NavBar";
import Floats from "../home/home components/Floats";
import CountUp from "react-countup";

const About = () => {
  return (
    <div>
      <NavBar />
      <section id="hero-about" className="hero-about d-flex align-items-center">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 d-flex flex-column justify-content-center">
              <h1>
                Welcome To <br /> Sanganabasava Residential PU College
              </h1>
              <h2>
                The Dream of all students is to study at the best and achieve a
                wonderful future.
              </h2>
              <div></div>
            </div>
            <div className="col-lg-6 hero-img-about">
              <img src={college} className="img-fluid" alt="" />
            </div>
          </div>
        </div>
      </section>
      <section id="about" class="about">
        <div class="container">
          <div class="row gx-0">
            <div class="col-lg-6 d-flex flex-column justify-content-center">
              <div class="content">
                <h3>Why you choose us ?</h3>
                <p>
                  Sanganabasava Residential PU college has a rich academic
                  tradition of 17 years and unique ethos that is held in high
                  esteem by all.Here at SRPU, we combine the best of traditional
                  values and educational practices with the most upto date
                  teaching techniques, technology, and resources for highly
                  effective and enjoyable teaching and learning.
                </p>
                <div class="text-center text-lg-start">
                  <Link
                    to="/about"
                    class="btn-read-more d-inline-flex align-items-center justify-content-center align-self-center"
                  >
                    <span>Read More</span>
                    <i class="bi bi-arrow-right"></i>
                  </Link>
                </div>
              </div>
            </div>
            <div class="col-lg-6 d-flex align-items-center">
              <iframe
                width="796"
                height="448"
                src="https://www.youtube.com/embed/sGSwqPrDhgU"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </section>

      <section id="counts" class="counts">
        <div class="container">
          <div class="row gt-4">
            <div class="col-lg-3 col-md-6">
              <div class="count-box ">
                <i class="fas fa-user-friends"></i>
                <div>
                  <CountUp
                    className="counter"
                    start={0}
                    end={521}
                    duration={1}
                  />
                  <p>Students</p>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-md-6">
              <div class="count-box">
                <i class="fas fa-chalkboard-teacher"></i>
                <div>
                  <CountUp
                    className="counter"
                    start={0}
                    end={36}
                    duration={1}
                  />
                  <p>Staffs</p>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-md-6">
              <div class="count-box">
                <i class="fab fa-readme"></i>
                <div>
                  <CountUp
                    className="counter"
                    start={0}
                    end={21}
                    duration={1}
                  />
                  <p>Courses</p>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-6">
              <div class="count-box">
                <i class="fas fa-hands-helping"></i>
                <div>
                  <CountUp className="counter" start={0} end={2} duration={1} />
                  <p>Branch</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <Floats />
    </div>
  );
};

export default About;
