import React from "react";
import { Link } from "react-router-dom";
import { Card, CardHead } from "react-bootstrap";
import yatnal from "../../../assets/img/yatnal.jpg";
import Basavaraj_S_Sugoor from "../../../assets/img/BoardofDirectors/Basavaraj_S_Sugoor.jpg";
import Basavaraj_Gani from "../../../assets/img/BoardofDirectors/Basavaraj_Gani.jpg";
import Basayya_S_Hiremath from "../../../assets/img/BoardofDirectors/Basayya_S_Hiremath.jpg";
import M_M_Sajjan from "../../../assets/img/BoardofDirectors/M_M_Sajjan.jpg";
import Madiwalappa_S_Karadi from "../../../assets/img/BoardofDirectors/Madiwalappa_S_Karadi.jpg";
import Ningondappa from "../../../assets/img/BoardofDirectors/Ningondappa.jpg";
import S_H_Nadagaouda from "../../../assets/img/BoardofDirectors/S_H_Nadagaouda.jpg";
import Sadanand_H_Desai from "../../../assets/img/BoardofDirectors/Sadanand_H_Desai.jpg";
import Sadashiv_Guddodagi from "../../../assets/img/BoardofDirectors/Sadashiv_Guddodagi.jpg";
import Sanganabasappa_G_Sajjan from "../../../assets/img/BoardofDirectors/Sanganabasappa_G_Sajjan.jpg";
import Shashidar_V_Hakkapakki from "../../../assets/img/BoardofDirectors/Shashidar_V_Hakkapakki.jpg";
import Shivanand_N_Neela from "../../../assets/img/BoardofDirectors/Shivanand_N_Neela.jpg";
import Sudir_Chinchali from "../../../assets/img/BoardofDirectors/Sudir_Chinchali.jpg";
import Vijayakumar_M_Doni from "../../../assets/img/BoardofDirectors/Vijayakumar_M_Doni.jpg";
import Footer from "../../home/home components/Footer";
import NavBar from "../../home/home components/NavBar";
import Floats from "../../home/home components/Floats";

const BoardOfDirectors = () => {
  return (
    <div>
      <NavBar />
      <section id="breadcrumbs" class="breadcrumbs">
        <div class="container">
          <ol>
            <li>
              <Link to={"/"}>Home</Link>
            </li>
            <li>About Us</li>
          </ol>
          <h2>Board Of Directors</h2>
        </div>
      </section>

      <h3 className="bod_heading">Shri Siddeshwar Samsthe - Governing Body</h3>

      <div className="bod_center">
        <img src={yatnal} alt="MLA" />
        <br /> <br />
        <p>
          <strong>Shri. Basanagouda R. Patil (Yatnal)</strong> <br />
          Member of Legislative Assembly <br />
          President, Shri Siddeshwar Samsthe
        </p>
      </div>
      <section id="values" class="values">
        <div class="container">
          <header class="section-header">
            <Card className="text-center">
              <Card.Header>
                <h4>Board Of Directors</h4>
              </Card.Header>
            </Card>
          </header>
          <div class="row ">
            <div class="col-lg-4 mt-4 mt-lg-0">
              <div class="box">
                <img src={Sanganabasappa_G_Sajjan} class="img-fluid" alt="" />
                <h3>Shri. Sanganabasappa.G.Sajjan</h3>
                <p>Vice-President</p>
              </div>
            </div>
            <div class="col-lg-4 mt-4 mt-lg-0">
              <div class="box">
                <img src={Basayya_S_Hiremath} class="img-fluid" alt="" />
                <h3>Shri. Basayya.S.Hiremath</h3>
                <p>Chairman</p>
              </div>
            </div>
            <div class="col-lg-4 mt-4 mt-lg-0">
              <div class="box">
                <img src={Sadanand_H_Desai} class="img-fluid" alt="" />
                <h3>Shri. Sadanand.H.Desai</h3>
                <p>Secretary</p>
              </div>
            </div>
            <div class="col-lg-4 mt-4 mt-lg-0">
              <div class="box">
                <img src={M_M_Sajjan} class="img-fluid" alt="" />
                <h3>Shri. M.M.Sajjan</h3>
                <p>Director</p>
              </div>
            </div>
            <div class="col-lg-4 ">
              <div class="box">
                <img src={Basavaraj_S_Sugoor} class="img-fluid" alt="" />
                <h3>Shri. Basavaraj.S.Sugoor</h3>
                <p>Director</p>
              </div>
            </div>
            <div class="col-lg-4 mt-4 mt-lg-0">
              <div class="box">
                <img src={Shashidar_V_Hakkapakki} class="img-fluid" alt="" />
                <h3>Shri. Shashidar.V.Hakkapakki</h3>
                <p>Director</p>
              </div>
            </div>
            <div class="col-lg-4 mt-4 mt-lg-0">
              <div class="box">
                <img src={Shivanand_N_Neela} class="img-fluid" alt="" />
                <h3>Shri. Shivanand.N.Neela</h3>
                <p>Director</p>
              </div>
            </div>
            <div class="col-lg-4 mt-4 mt-lg-0">
              <div class="box">
                <img src={Madiwalappa_S_Karadi} class="img-fluid" alt="" />
                <h3>Shri. Madiwalappa.S.Karadi</h3>
                <p>Director</p>
              </div>
            </div>
            <div class="col-lg-4 mt-4 mt-lg-0">
              <div class="box">
                <img src={Ningondappa} class="img-fluid" alt="" />
                <h3>Shri. Ningondappa.M.Golai</h3>
                <p>Director</p>
              </div>
            </div>
            <div class="col-lg-4 mt-4 mt-lg-0">
              <div class="box">
                <img src={Vijayakumar_M_Doni} class="img-fluid" alt="" />
                <h3>Shri. Vijayakumar.M.Doni</h3>
                <p>Director</p>
              </div>
            </div>
            <div class="col-lg-4 mt-4 mt-lg-0">
              <div class="box">
                <img src={Basavaraj_Gani} class="img-fluid" alt="" />
                <h3>Shri. Basavaraj.Gani</h3>
                <p>Director</p>
              </div>
            </div>
            <div class="col-lg-4 mt-4 mt-lg-0">
              <div class="box">
                <img src={S_H_Nadagaouda} class="img-fluid" alt="" />
                <h3>Shri. S.H.Nadagaouda</h3>
                <p>Director</p>
              </div>
            </div>
            <div class="col-lg-4 mt-4 mt-lg-0">
              <div class="box">
                <img src={Sudir_Chinchali} class="img-fluid" alt="" />
                <h3>Shri. Sudir.Chinchali</h3>
                <p class="post-date-name">Director</p>
              </div>
            </div>
            <div class="col-lg-4 mt-4 mt-lg-0">
              <div class="box">
                <img src={Sadashiv_Guddodagi} class="img-fluid" alt="" />
                <h3>Shri. Sadashiv.Guddodagi</h3>
                <p class="post-date-name">Director</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <Floats />
    </div>
  );
};

export default BoardOfDirectors;
