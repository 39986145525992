import React from "react";
import { Link } from "react-router-dom";
import Footer from "../../home/home components/Footer";
import NavBar from "../../home/home components/NavBar";
import college from "../../../assets/img/Infrastructure/college.jpg";
import Buildings_DSC_0496 from "../../../assets/img/Infrastructure/Buildings_DSC_0496.JPG";
import Floats from "../../home/home components/Floats";

const College = () => {
  return (
    <div>
      <NavBar />
      <section id="breadcrumbs" class="breadcrumbs">
        <div class="container">
          <ol>
            <li>
              <Link to={"/"}>Home</Link>
            </li>
            <li>About Us</li>
          </ol>
          <h2>About SRPU College</h2>
        </div>
      </section>
      {/* <!-- End Breadcrumbs --> */}

      {/* learning part start */}
      <section id="Staffs" class="Staffs">
        <div class="row ">
          <div class="col-lg-4">
            <div class="post-box">
              <div class="post-img">
                <img src={college} class="img-fluid center" alt="" />
              </div>
            </div>
          </div>

          <div class="col-lg-8 ">
            <div class="post-box">
              <h5 class="post-title">ABOUT SRPU COLLEGE: </h5>
              The seventeen years from 2004-2021 has been a glorious period for
              SRPU college .It was the period in which Sanganabasava Residential
              PU college made its mark as an educational institute of
              outstanding excellence. Vijayapur today has a global institution
              of excellence. Our toppers at the PU level score 100/100 in
              several subjects and have secured a place among the most brilliant
              students of Karnataka and India <br />
              <br />
              The glory of our institution is not only it produces top scorers
              of the state and the country but also it transforms average
              students into creditable achievers. We train our students to
              achieve their maximum level of performance. We are committed to
              make every student perform to his highest potential.
              <br />
              <br />
            </div>
          </div>
        </div>
      </section>

      <section id="Staffs" class="Staffs">
        <div class="row ">
          <div class="col-lg-12">
            <div class="post-box">
              We provide a holistic education. We ensure the physical, mental,
              social and spiritual development of every student. In addition to
              games, we provide training in yoga, karate and aerobics to ensure
              the physical fitness of every student. The morning assembly is
              planned meticulously to provide the students the experience of
              community, prayer and human values that are so important to make
              life purposeful.
              <br />
              <br />
              The College has ventured into program of social transformation in
              collaboration with the Government and local civic bodies. The
              latest program in which the College involved is “Swachh Bharat”
              and awareness for Covid-19 vaccination drive. The College has
              adopted two villages Kavalagi and Honnutagi for comprehensive
              development programs. We believe that education is meant for the
              transformation of the society.
            </div>
          </div>
        </div>
      </section>

      <section id="about-home" class="about-home">
        <div class="container">
          <div class="row gx-0">
            <div class="col-lg-6 d-flex flex-column justify-content-center">
              <div class="content">
                <h3>Why you choose us ?</h3>
                <p>
                  {" "}
                  Sanganabasava Residential PU College of science and commerce
                  has a rich academic tradition of 17 years and unique ethos
                  that is held in high esteem by all. Here at SRPU, we combine
                  the best of traditional values and educational practices with
                  the most up to date teaching techniques, technology, and
                  resources for highly effective and enjoyable teaching and
                  learning.
                </p>
              </div>
            </div>

            <div class="col-lg-6 d-flex align-items-center">
              <img src={Buildings_DSC_0496} class="img-fluid" alt="" />
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <Floats />
    </div>
  );
};

export default College;
