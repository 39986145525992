import React from "react";
import { Link } from "react-router-dom";
import Floats from "../home/home components/Floats";
import Footer from "../home/home components/Footer";
import NavBar from "../home/home components/NavBar";

const Contact = () => {
  return (
    <div>
      <NavBar />
      <section id="breadcrumbs_Contact" class="breadcrumbs_Contact">
        <div class="container">
          <h2>CONTACT US!</h2>
        </div>
      </section>
      {/* <!-- ======= Contact Section ======= --> */}
      <section id="contact" class="contact">
        <div class="container">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3819.021084317825!2d75.85346201486858!3d16.825310088417787!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x8d81afb77422d44f!2zMTbCsDQ5JzMxLjEiTiA3NcKwNTEnMjAuMyJF!5e0!3m2!1sen!2sin!4v1641531771797!5m2!1sen!2sin"
            width="100%"
            height="450"
            frameborder="0"
            style={{ border: 0 }}
            allowfullscreen=""
            aria-hidden="false"
            tabindex="0"
          ></iframe>
        </div>
      </section>
      <section id="contact" class="contact">
        <div className="container">
          <div class="row gy-4">
            <div class="col-lg-6">
              <div class="row gy-4">
                <div class="col-md-12">
                  <div class="info-box">
                    <i class="fas fa-map-marker-alt name-icon"> Address</i>
                    <br />
                    <br />
                   
                    <p>
                      Sanganabasava Residential PU college for Science and
                      Commerce Kavalagi.
                      <br />
                      Post : Honnutagi <br /> District: Vijayapura, Karnataka –
                      586127
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="row gy-4">
                <div class="col-md-6">
                  <div class="info-box ">
                    <i class="fas fa-phone-volume name-icon"> Phone</i>
                    <br />
                    <br />

                    <p>
                      {/* +91 8660839779
                      <br /> */}
                      +91 9880608303 <br /> 
                      +91 9113281526
                    </p>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="info-box ">
                    <i class="fas fa-envelope-open-text name-icon"> Email-Id</i>
                    <br />
                    <br />

                    <p>srpucollege.kavalagi@gmail.com</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
      <Floats />
    </div>
  );
};

export default Contact;
