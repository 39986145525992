import Recat from "react";
import { Link } from "react-router-dom";
import Basayya_Hiremath from "../../../assets/img/Basayya_Hiremath.JPG";
import Floats from "../../home/home components/Floats";
import Footer from "../../home/home components/Footer";
import NavBar from "../../home/home components/NavBar";

const ChairmanMessage = () => {
  return (
    <div>
      <NavBar />
      <section id="breadcrumbs" class="breadcrumbs">
        <div class="container">
          <ol>
            <li>
              <Link to={"/"}>Home</Link>
            </li>
            <li>About Us</li>
          </ol>
          <h2>Chairman’s Message</h2>
        </div>
      </section>
      {/* <!-- End Breadcrumbs --> */}

      <section id="Staffs" class="Staffs">
        <div class="row ">
          <div class="col-lg-4">
            <div class="post-box">
              <div class="post-img">
                <img src={Basayya_Hiremath} class="img-fluid center" alt="" />
              </div>
              <h5 class="post-title">Shri. Basayya.S.Hiremath </h5>
              <span class="post-date">
                <strong>
                  Chairman, <br />
                  Shri Siddeshwara Samsthe , Vijayapur
                </strong>
              </span>
            </div>
          </div>

          <div class="col-lg-8 ">
            <div class="post-box">
              <h5 class="post-title">Chairman’s Message: </h5>
              Let it go from untruth to truth … from darkness to light….. from
              death to immortality. One such immortal and divine personality is
              Parama Poojya Sanganabasava Shivayogigalu, who radiated passion
              for serving the people beyond caste, religion and region
              <br />
              <br />
              Our mother land has been giving birth to many such divine
              personalities who tirelessly are working for humanity. Lives of
              great men all remind us, inspire us, we can make our life
              enlightened. And we can carry the flag of service to man is to
              service to god, to all the needy.
              <br />
              <br />
              Swamiji was a down to earth activist, who called attention not to
              him, but a humble saint who loved mankind as a whole .His over
              flowing compassion to the people, who were not the opportunity
              made him to pool resources to better height. He wants to instill
              and imbibe the age tested sanskruti of bharat to reach all.
              <br />
              <br />
            </div>
          </div>
        </div>
      </section>

      <section id="Staffs" class="Staffs">
        <div class="row ">
          <div class="col-lg-12">
            <div class="post-box">
              SIRS and SRPU College are internationally renowned institutions
              which cater all round development through modern technological
              approach based on India’s great values with a global vision. The
              recent achievements show the academic excellence and success
              stories in sports and cultural events. We have introduced
              CET/NEET/JEE/CET/CACPT/ etc foundation course and integrated
              programmes from VIII to PU II and give the children ample scope of
              success in their competitive examinations.
              <br />
              <br />
              Let us all flock together to imbibe and inculcate the inspiring
              life and words of parama poojya sanganabasava shivayogigalu in the
              young generation through right education and other service
              activities that are undertaken by Shri Siddeshwara Samsthe.Let the
              blessings of Parama Poojya Sanganabasava Shivayogigalu be with us
              all in our humble Endeavour’s.
              <br />
              <br />
            </div>
          </div>
        </div>
      </section>

      <Footer />
      <Floats />
    </div>
  );
};

export default ChairmanMessage;
