import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
 // aos CSS 
import "./assets/css/aos/aos.css"
// Animate CSS
import "./assets/css/animate.css/animate.compat.css"
import "./assets/css/animate.css/animate.css"
import "./assets/css/animate.css/animate.min.css"

 //Bootstrap CSS
import "./assets/css/bootstrap/bootstrap-grid.css"
import "./assets/css/bootstrap/bootstrap-grid.css.map"
import "./assets/css/bootstrap/bootstrap-grid.min.css"
import "./assets/css/bootstrap/bootstrap-grid.min.css.map"
import "./assets/css/bootstrap/bootstrap-grid.rtl.css"
import "./assets/css/bootstrap/bootstrap-grid.rtl.css.map"
import "./assets/css/bootstrap/bootstrap-grid.rtl.min.css"
import "./assets/css/bootstrap/bootstrap-grid.rtl.min.css.map"
import "./assets/css/bootstrap/bootstrap-reboot.css"
import "./assets/css/bootstrap/bootstrap-reboot.css.map"
import "./assets/css/bootstrap/bootstrap-reboot.min.css"
import "./assets/css/bootstrap/bootstrap-reboot.min.css.map"
import "./assets/css/bootstrap/bootstrap-reboot.rtl.css"
import "./assets/css/bootstrap/bootstrap-reboot.rtl.css.map"
import "./assets/css/bootstrap/bootstrap-reboot.rtl.min.css"
import "./assets/css/bootstrap/bootstrap-reboot.rtl.min.css.map"
import "./assets/css/bootstrap/bootstrap-utilities.css"
import "./assets/css/bootstrap/bootstrap-utilities.css.map"
import "./assets/css/bootstrap/bootstrap-utilities.min.css"
import "./assets/css/bootstrap/bootstrap-utilities.min.css.map"
import "./assets/css/bootstrap/bootstrap-utilities.rtl.css"
import "./assets/css/bootstrap/bootstrap-utilities.rtl.css.map"
import "./assets/css/bootstrap/bootstrap-utilities.rtl.min.css"
import "./assets/css/bootstrap/bootstrap-utilities.rtl.min.css.map"
import "./assets/css/bootstrap/bootstrap.css"
import "./assets/css/bootstrap/bootstrap.css.map"
import "./assets/css/bootstrap/bootstrap.min.css"
import "./assets/css/bootstrap/bootstrap.min.css.map"
import "./assets/css/bootstrap/bootstrap.rtl.css"
import "./assets/css/bootstrap/bootstrap.rtl.css.map"
import "./assets/css/bootstrap/bootstrap.rtl.min.css"
import "./assets/css/bootstrap/bootstrap.rtl.min.css.map"

//Boxicons CSS 
// import "./assets/css/boxicons/css/animations.css"
// import "./assets/css/boxicons/css/boxicons.css"
// import "./assets/css/boxicons/css/boxicons.min.css"
// import "./assets/css/boxicons/css/transformations.css"

// swiper
import "./assets/css/swiper/swiper-bundle.min.css"
 // style CSS 
 import "./assets/css/style.css"




ReactDOM.render(
  
    <App />
, 
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
