import React from "react";
import { Link } from "react-router-dom";
import { Card, CardHeader } from "react-bootstrap";
import Floats from "../../home/home components/Floats";
import Footer from "../../home/home components/Footer";
import NavBar from "../../home/home components/NavBar";
import chemistry1 from "../../../assets/img/Infrastructure/chemistry1.JPG";
import chemistry from "../../../assets/img/Infrastructure/chemistry.JPG";
import physics1a from "../../../assets/img/Infrastructure/physics1a.JPG";
import physics11 from "../../../assets/img/Infrastructure/physics11.JPG";
import bio1 from "../../../assets/img/Infrastructure/bio1.jpg";
import bio2 from "../../../assets/img/Infrastructure/bio2.JPG";
import cs1 from "../../../assets/img/Infrastructure/cs1.JPG";
import cs2 from "../../../assets/img/Infrastructure/cs2.JPG";
import dining1 from "../../../assets/img/Infrastructure/dining1.JPG";
import dining2 from "../../../assets/img/Infrastructure/dining2.JPG";
import dining3 from "../../../assets/img/Infrastructure/dining3.JPG";
import dining4 from "../../../assets/img/Infrastructure/dining4.JPG";
import Buildings_Hostel1 from "../../../assets/img/Infrastructure/Buildings_Hostel1.JPG";
import Buildings_Hostel2 from "../../../assets/img/Infrastructure/Buildings_Hostel2.JPG";
import Buildings_Hostel3 from "../../../assets/img/Infrastructure/Buildings_Hostel3.JPG";
import Buildings_Dinning_Hall from "../../../assets/img/Infrastructure/Buildings_Dinning_Hall.JPG";
import library from "../../../assets/img/Infrastructure/library.JPG";
import LIBRARY2 from "../../../assets/img/Infrastructure/LIBRARY2.JPG";
import sports from "../../../assets/img/Infrastructure/sports.JPG";
import sports1 from "../../../assets/img/Infrastructure/sports1.JPG";

const Facility = () => {
  return (
    <div>
      <NavBar />
      <section id="breadcrumbs" class="breadcrumbs">
        <div class="container">
          <ol>
            <li>
              <Link to={"/"}>Home</Link>
            </li>
            <li>About Us</li>
          </ol>
          <h2>Infrastructure</h2>
        </div>
      </section>
      {/* <!-- End Breadcrumbs --> */}
      <section id="Infrastructure" class="Infrastructure">
        <div class="container">
          <div class="row infra-lab">
            <h2>SCIENCE LABORATORIES</h2>
            <br />
            <br />
            <p>
              {" "}
              Sanganabasava Residential PU college is well equipped with state
              of the art laboratories wherein the students gain a meaningful
              coherence between theory and practical sessions.
            </p>
          </div>
        </div>
      </section>

      <section id="Infrastructure" class="Infrastructure">
        <div class="container">
          <div class="row">
            <header class="section-header">
              <Card className="text-center">
                <Card.Header>
                  <h4>CHEMISTRY LABORATORY</h4>{" "}
                </Card.Header>
              </Card>
            </header>
            <p>
              A place that develops inmate futuristic atmosphere. The labs are
              well equipped and can accommodate 20 students in a batch. Well
              qualified and skilled staff empowers the students to respond with
              maximum velocity.
            </p>

            <div class="col-lg-6">
              <div class="post-box">
                <div class="post-img">
                  <img src={chemistry1} class="img-fluid center" alt="" />
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="post-box">
                <div class="post-img">
                  <img src={chemistry} class="img-fluid center" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="Infrastructure" class="Infrastructure">
        <div class="container">
          <div class="row">
            <header class="section-header">
              <Card className="text-center">
                <Card.Header>
                  <h4>PHYSICS LABORATORY</h4>
                </Card.Header>
              </Card>
            </header>
            <p>
              A well equipped lab, spacious enough to accommodate about 20
              students in a batch. An excellent training around, for problem
              solving practical understudies of the subject through experiments
              taught by expert teachers.
            </p>

            <div class="col-lg-6">
              <div class="post-box">
                <div class="post-img">
                  <img src={physics1a} class="img-fluid center" alt="" />
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="post-box">
                <div class="post-img">
                  <img src={physics11} class="img-fluid center" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="Infrastructure" class="Infrastructure">
        <div class="container">
          <div class="row">
            <header class="section-header">
              <Card className="text-center">
                <Card.Header>
                  <h4>BIOLOGY LABORATORY</h4>
                </Card.Header>
              </Card>
            </header>
            <p>
              The lab is well maintained with bio-visual aids, models, slides,
              optical instruments which provides students with a better
              understanding of basic biological concepts.{" "}
            </p>
            <div class="col-lg-6">
              <div class="post-box">
                <div class="post-img">
                  <img src={bio1} class="img-fluid center" alt="" />
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="post-box">
                <div class="post-img">
                  <img src={bio2} class="img-fluid center" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="Infrastructure" class="Infrastructure">
        <div class="container">
          <div class="row">
            <header class="section-header">
              <Card className="text-center">
                <Card.Header>
                  <h4>COMPUTER SCIENCE LABORATORY</h4>
                </Card.Header>
              </Card>
            </header>
            <p>
              The college has an efficient computer lab to cater to the needs of
              the ever growing sophistication in the information technology.
            </p>
            <div class="col-lg-6">
              <div class="post-box">
                <div class="post-img">
                  <img src={cs1} class="img-fluid center" alt="" />
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="post-box">
                <div class="post-img">
                  <img src={cs2} class="img-fluid center" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="Infrastructure" class="Infrastructure">
        <div class="container">
          <div class="row">
            <header class="section-header">
              <Card className="text-center">
                <Card.Header>
                  <h4>LIBRARIES</h4>
                </Card.Header>
              </Card>
            </header>
            <p>
              The college has one of the best equipped libraries. It is spacious
              enough to accommodate 50 students at a time with an exhaustive
              collection of books, magazines, Journals, newspapers etc. Library
              is an important learning resource centre with open access systems
              encouraging the user to browse freely in the tack area.. The
              library provides the information and reference in the academic
              programs and innovations in different fields. There are also vast
              that provide materials insight and information and personality.
              Students can also access to university question papers and
              Question banks
            </p>
            <div class="col-lg-6">
              <div class="post-box">
                <div class="post-img">
                  <img src={library} class="img-fluid center" alt="" />
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="post-box">
                <div class="post-img">
                  <img src={LIBRARY2} class="img-fluid center" alt="" />
                </div>
              </div>
            </div>
            <div></div>
          </div>
        </div>
      </section>

      <section id="Infrastructure" class="Infrastructure">
        <div class="container">
          <div class="row">
            <header class="section-header">
              <Card className="text-center">
                <Card.Header>
                  <h4>SPORTS</h4>
                </Card.Header>
              </Card>
            </header>
            <p>
              SR PU college ensures a healthy holistic life style within the
              student community by encouraging then to participate in sports. To
              facilitate physical education and sporting activities, expert
              coaching is provided for a variety of games like throw ball,
              basket ball and badminton: indoor games like chess, carom, and
              table tennis are also popular among student.
            </p>
            <div class="col-lg-6">
              <div class="post-box">
                <div class="post-img">
                  <img src={sports} class="img-fluid center" alt="" />
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="post-box">
                <div class="post-img">
                  <img src={sports1} class="img-fluid center" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!------- End of Infrastructure Sports Section -------> */}
      <section id="Infrastructure" class="Infrastructure">
        <div class="container">
          <div class="row">
            <header class="section-header">
              <h4>RESIDENTIAL FACILITIES</h4>
            </header>
            <p>
              SRPU provides accommodation to all students which include their
              food, laundry, recreation, health care and other grooming support.
              The rooms are spacious and house 4 to 6 students in each room. A
              warden and the resident teachers are overall responsible for the
              students safety, guidance and care. Hygienic kitchens,
              supervision, laundry, medical checks ups, exercise and fitness are
              just some of the amenities. Each room is furnished with individual
              beds, table and individual storage space for clothing.
              Housekeeping is taken care of and the rooms are maintained neat
              and tidy.
            </p>
            <div class="col-lg-6">
              <div class="post-box">
                <div class="post-img">
                  <img
                    src={Buildings_Hostel1}
                    class="img-fluid center"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="post-box">
                <div class="post-img">
                  <img
                    src={Buildings_Hostel2}
                    class="img-fluid center"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="post-box">
                <div class="post-img">
                  <img
                    src={Buildings_Hostel3}
                    class="img-fluid center"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="post-box">
                <div class="post-img">
                  <img
                    src={Buildings_Dinning_Hall}
                    class="img-fluid center"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="Infrastructure" class="Infrastructure">
        <div class="container">
          <div class="row">
            <div>
              <header class="section-header">
                <h4>MENU</h4>
              </header>
              <p>
                The menu is a blend of nutrition and is of hygienic value. The
                menu offerings is changed periodically and a balanced meal is
                offered to all students.
              </p>
              <br /> <br />
              <header class="section-header">
                <h4>UNIFORMS</h4>
              </header>
              <p>
                Uniforms both boarding and college. Track pant, House dress and
                college T shirt once in week. Boarding: House Uniform
              </p>
            </div>
            <div class="col-lg-6">
              <div class="post-box">
                <div class="post-img">
                  <img src={dining1} class="img-fluid center" alt="" />
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="post-box">
                <div class="post-img">
                  <img src={dining2} class="img-fluid center" alt="" />
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="post-box">
                <div class="post-img">
                  <img src={dining3} class="img-fluid center" alt="" />
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="post-box">
                <div class="post-img">
                  <img src={dining4} class="img-fluid center" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <Floats />
    </div>
  );
};
export default Facility;
