import React from "react";
import { Link } from "react-router-dom";
import Footer from "../../home/home components/Footer";
import NavBar from "../../home/home components/NavBar";
import swami from "../../../assets/img/Swami.png";
import Floats from "../../home/home components/Floats";

const Swami = () => {
  return (
    <div>
      <NavBar />
      <section id="breadcrumbs" class="breadcrumbs">
        <div class="container">
          <ol>
            <li>
              <Link to={"/"}>Home</Link>
            </li>
            <li>About Us</li>
          </ol>
          <h2>Shri Shri Shri Sanganabasava Shivayogi of Banthanal</h2>
        </div>
      </section>
      {/* <!-- End Breadcrumbs --> */}

      {/* learning part start */}
      <section id="Staffs" class="Staffs">
        <div class="row ">
          <div class="col-lg-4">
            <div class="post-box" style={{ width: 270, padding: 0 }}>
              <div class="post-img" style={{ width: 300 }}>
                <img src={swami} class="img-fluid center" alt="" />
              </div>
            </div>
          </div>

          <div class="col-lg-8 ">
            <div class="post-box" style={{ height: 200 }}>
              <h5 class="post-title">
                Shri Shri Shri Sanganabasava Shivayogi of Banthanal
              </h5>
              “My Dream is to facilitate, build a perfect human values and the
              socio-cultural heritage of the country. The need of the hour is to
              build good residential schools. We require temples of learning and
              not temples of worship. Students are my GOD and achieving their
              bright future is my dream”.
            </div>
          </div>
        </div>
      </section>

      <Footer />
      <Floats />
    </div>
  );
};

export default Swami;
