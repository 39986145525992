import React from "react";
import { Link } from "react-router-dom";
import { Card, CardHead } from "react-bootstrap";
import VICE_PRINCIPAL from "../../../assets/img/Staffs/CommerceStaffs/VICE_PRINCIPAL.jpg";
import DR_RABINCHANDRA from "../../../assets/img/Staffs/ScienceStaffs/DR_RABINCHANDRA.JPG";
import FAREEDA_UKKALI from "../../../assets/img/Staffs/CommerceStaffs/FAREEDA_UKKALI.JPG";
import KIRANKUMAR_B_G from "../../../assets/img/Staffs/CommerceStaffs/KIRANKUMAR_B_G.JPG";
import JAYALAXMI from "../../../assets/img/Staffs/CommerceStaffs/JAYALAXMI.JPG";
import SHRIKANT_JALIHAL from "../../../assets/img/Staffs/CommerceStaffs/SHRIKANT_JALIHAL.JPG";
import PAVAN_HIREMATH from "../../../assets/img/Staffs/CommerceStaffs/PAVAN_HIREMATH.JPG";
import SAVITRI_KOMSHIRASANGI from "../../../assets/img/Staffs/CommerceStaffs/SAVITRI_KOMSHIRASANGI.JPG";
import Sujata_Chavan from "../../../assets/img/Staffs/CommerceStaffs/Sujata_Chavan.jpg";
import Floats from "../../home/home components/Floats";
import Footer from "../../home/home components/Footer";
import NavBar from "../../home/home components/NavBar";

const CommerceStaffs = () => {
  return (
    <div>
      <NavBar />
      <section id="breadcrumbs" class="breadcrumbs">
        <div class="container">
          <ol>
            <li>
              <Link to={"/"}>Home</Link>
            </li>
            <li>Streams</li>
          </ol>
          <h2>COMMERCE STREAM STAFF</h2>
        </div>
      </section>

      <section id="Staffs" class="Staffs">
        <div class="container">
          <div class="row">
            {/* <div class="col">
              <div class="post-box" style={{ width: 400, height: 500 }}>
                <h5 style={{ marginBottom: 50, textAlign: "center" }}>
                  {" "}
                  PRINICIPAL
                </h5>
                <div class="post-img">
                  <img src={DR_RABINCHANDRA} class="img-fluid center" alt="" />
                </div>
                <h5 class="post-title">Dr. RABIN CHANDRA PARAMANIK</h5>
                <span class="post-date ">
                  M.Sc,M.Phil,Ph.D,F.S.Sc,F.S.E.Sc,F.I.S.E.C,
                  F.I.S.B.T.F.I.S.C.A.,D.Litt(U.S.A),(D.Sc).,P.M.I.R&L.W
                </span>
              </div>
            </div> */}

            <div class="col">
              <div class="post-box" style={{ width: 400, height: 500 }}>
                <h5 style={{ marginBottom: 50, textAlign: "center" }}>
                  {" "}
                  VICE-PRINICIPAL
                </h5>
                <div class="post-img">
                  <img src={VICE_PRINCIPAL} class="img-fluid center" alt="" />
                </div>
                <h5 class="post-title">MR. VEERESH P</h5>
                <span class="post-date"> MBA</span>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="Staffs" class="Staffs">
        <div class="container">
          <div class="row">
            <header class="section-header">
              <Card className="text-center">
                <Card.Header>
                  <h5>DEPARTMENT OF COMMERCE</h5>
                </Card.Header>
              </Card>
            </header>
            <div class="col-lg-4">
              <div class="post-box">
                <div class="post-img">
                  <img src={PAVAN_HIREMATH} class="img-fluid center" alt="" />
                </div>
                <h5 class="post-title">Mr. Pavankumar Hiremath </h5>
                <span class="post-date fas fa-graduation-cap">
                  {" "}
                  M.Com , B.Ed , MBA
                </span>
              </div>
            </div>

            <div class="col-lg-4">
              <div class="post-box">
                <div class="post-img">
                  <img src={Sujata_Chavan} class="img-fluid center" alt="" />
                </div>
                <h5 class="post-title">Miss. Sujata Chavan</h5>
                <span class="post-date fas fa-graduation-cap"> M.Com</span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="Staffs" class="Staffs">
        <div class="container">
          <div class="row">
            <header class="section-header">
              <Card className="text-center">
                <Card.Header>
                  <h5>DEPARTMENT OF COMPUTER SCIENCE</h5>
                </Card.Header>
              </Card>
            </header>
            <div class="col-lg-4">
              <div class="post-box">
                <div class="post-img">
                  <img src={KIRANKUMAR_B_G} class="img-fluid center" alt="" />
                </div>
                <h5 class="post-title">Mr. Kirankumar.B.G </h5>
                <span class="post-date fas fa-graduation-cap">
                  {" "}
                  B.E , M.Tech
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="Staffs" class="Staffs">
        <div class="container">
          <div class="row">
            <header class="section-header">
              <Card className="text-center">
                <Card.Header>
                  <h5>DEPARTMENT OF LANGUAGES</h5>
                </Card.Header>
              </Card>
            </header>
            <div class="col-lg-4">
              <div class="post-box">
                <div class="post-img">
                  <img
                    src={SAVITRI_KOMSHIRASANGI}
                    class="img-fluid center"
                    alt=""
                  />
                </div>
                <h5 class="post-title">Miss. Savitri Komshirasangi </h5>
                <span class="post-date fas fa-graduation-cap"> M.A , B.Ed</span>
                <span class="post-date-name">Asst Prof  Kannada</span>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="post-box">
                <div class="post-img">
                  <img src={JAYALAXMI} class="img-fluid center" alt="" />
                </div>
                <h5 class="post-title">Mrs. Jayalaxmi Anawal</h5>
                <span class="post-date fas fa-graduation-cap">
                  {" "}
                  M.A , B.Ed , M.Ed
                </span>
                <span class="post-date-name">Asst Prof English</span>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="post-box">
                <div class="post-img">
                  <img src={FAREEDA_UKKALI} class="img-fluid center" alt="" />
                </div>
                <h5 class="post-title">Mrs. Farida Ukkali </h5>
                <span class="post-date fas fa-graduation-cap"> M.A , B.Ed</span>
                <span class="post-date-name">Asst Prof Hindi</span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="Staffs" class="Staffs">
        <div class="container">
          <div class="row">
            <header class="section-header">
              <Card className="text-center">
                <Card.Header>
                  <h5>NON - TEACHING STAFF</h5>
                </Card.Header>
              </Card>
            </header>
            <div class="col-lg-4">
              <div class="post-box">
                <div class="post-img">
                  <img src={SHRIKANT_JALIHAL} class="img-fluid center" alt="" />
                </div>
                <h5 class="post-title">Mr. Shrikant Jalihal</h5>
                <span class="post-date fas fa-graduation-cap"> B.SC</span>
                <span class="post-date-name">FDA, Accountant</span>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
      <Floats />
    </div>
  );
};
export default CommerceStaffs;
