import chemistry from "../../../assets/img/Infrastructure/chemistry.JPG";
import chemistry1 from "../../../assets/img/Infrastructure/chemistry1.JPG";
import physics1a from "../../../assets/img/Infrastructure/physics1a.JPG";
import physics11 from "../../../assets/img/Infrastructure/physics11.JPG";
import bio1 from "../../../assets/img/Infrastructure/bio1.jpg";
import bio2 from "../../../assets/img/Infrastructure/bio2.JPG";
import cs1 from "../../../assets/img/Infrastructure/cs1.JPG";
import cs2 from "../../../assets/img/Infrastructure/cs2.JPG";
import library from "../../../assets/img/Infrastructure/library.JPG";
import LIBRARY2 from "../../../assets/img/Infrastructure/LIBRARY2.JPG";
import sports from "../../../assets/img/Infrastructure/sports.JPG";
import sports1 from "../../../assets/img/Infrastructure/sports1.JPG";
import dining1 from "../../../assets/img/Infrastructure/dining1.JPG";
import dining2 from "../../../assets/img/Infrastructure/dining2.JPG";
import dining3 from "../../../assets/img/Infrastructure/dining3.JPG";
import dining4 from "../../../assets/img/Infrastructure/dining4.JPG";
import Buildings_Hostel1 from "../../../assets/img/Infrastructure/Buildings_Hostel1.JPG";
import Buildings_Hostel2 from "../../../assets/img/Infrastructure/Buildings_Hostel2.JPG";
import Buildings_Hostel3 from "../../../assets/img/Infrastructure/Buildings_Hostel3.JPG";
import Buildings_Dinning_Hall from "../../../assets/img/Infrastructure/Buildings_Dinning_Hall.JPG";
const PortfolioDetails = [
  {
    id: 1,
    url: bio1,
    name: "Biology",
    category: "Laboratories",
  },
  {
    id: 2,
    url: bio2,
    name: "Biology",
    category: "Laboratories",
  },
  {
    id: 3,
    url: physics1a,
    name: "Physics",
    category: "Laboratories",
  },
  {
    id: 4,
    url: physics11,
    name: "Physics",
    category: "Laboratories",
  },
  {
    id: 5,
    url: chemistry,
    name: "Chemistry",
    category: "Laboratories",
  },
  {
    id: 6,
    url: chemistry1,
    name: "Chemistry",
    category: "Laboratories",
  },
  {
    id: 7,
    url: cs1,
    name: "Computer Science",
    category: "Laboratories",
  },
  {
    id: 8,
    url: cs2,
    name: "Computer Science",
    category: "Laboratories",
  },
  {
    id: 9,
    url: sports,
    name: "Sports",
    category: "Sports",
  },
  {
    id: 10,
    url: sports1,
    name: "Sports",
    category: "Sports",
  },
  {
    id: 11,
    url: Buildings_Hostel1,
    name: "Hostel",
    category: "Hostel",
  },
  {
    id: 12,
    url: Buildings_Hostel2,
    name: "Hostel",
    category: "Hostel",
  },
  {
    id: 13,
    url: Buildings_Hostel3,
    name: "Hostel",
    category: "Hostel",
  },
  {
    id: 14,
    url: library,
    name: "Library",
    category: "Libraries",
  },
  {
    id: 15,
    url: LIBRARY2,
    name: "Library",
    category: "Libraries",
  },
  {
    id: 16,
    url: Buildings_Dinning_Hall,
    name: "Annapoorna",
    category: "Annapoorna",
  },
  {
    id: 17,
    url: dining1,
    name: "Annapoorna",
    category: "Annapoorna",
  },
  {
    id: 18,
    url: dining2,
    name: "Annapoorna",
    category: "Annapoorna",
  },
  {
    id: 19,
    url: dining3,
    name: "Annapoorna",
    category: "Annapoorna",
  },
  {
    id: 20,
    url: dining4,
    name: "Annapoorna",
    category: "Annapoorna",
  },
];

export default PortfolioDetails;
