import React from "react";
import { FaWhatsapp } from "react-icons/fa";
import { FaPhone } from "react-icons/fa";

const Floats = () => {
  return (
    <div>
      <a
        href="https://wa.me/+919113281526"
        class="whatsapp_float"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaWhatsapp />
      </a>

      <a href="tel:+919113281526" class="callme_float">
        <FaPhone />
      </a>
    </div>
  );
};

export default Floats;
