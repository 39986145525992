import React from "react";
import { Link } from "react-router-dom";
import vision from "../../../assets/img/vision.png";
import mission from "../../../assets/img/mission.png";
import Footer from "../../home/home components/Footer";
import NavBar from "../../home/home components/NavBar";
import Floats from "../../home/home components/Floats";

const VisionandMission = () => {
  return (
    <div>
      <NavBar />
      <section id="breadcrumbs" className="breadcrumbs">
        <div className="container">
          <ol>
            <li>
              <Link to={"/"}>Home</Link>
            </li>
            <li>About Us</li>
          </ol>
          <h2>Vision and Mission</h2>
        </div>
      </section>

      <div className="how-section1">
        <div className="row">
          <div className="col-md-6 how-img">
            <img src={vision} className="  rounded-circle img-fluid" alt="" />
          </div>
          <div className="col-md-6">
            <h2 className="about-heading">Our Vision</h2>
            <div className="border-line"></div>
            <p className="text-muted">
              "Every child growing to his fullness, physically and
              intellectually, emotionally and socially, aesthetically and
              spiritually in an atmosphere of joy, freedom and responsibility
              and wining his way in life".
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <h2 className="about-heading">Our Mission</h2>
            <div className="border-line"></div>
            <p className="text-muted">
              "Empowerment through Education”, the school is committed to
              empower every child through education-knowledge, wisdom, skills to
              succeed in life by becoming fully human and fully alive. Education
              here at the school is “programmed” to empower to transform himself
              and transform the world he lives in".
            </p>
          </div>
          <div className="col-md-6 how-img">
            <img src={mission} className="rounded-circle img-fluid" alt="" />
          </div>
        </div>
      </div>
      <Footer />
      <Floats />
    </div>
  );
};
export default VisionandMission;
