import React from "react";
import { Link } from "react-router-dom";
import { Card, CardHeader } from "react-bootstrap";
import Floats from "../../home/home components/Floats";
import Footer from "../../home/home components/Footer";
import NavBar from "../../home/home components/NavBar";
import chemistry1 from "../../../assets/img/Infrastructure/chemistry1.JPG";
import chemistry from "../../../assets/img/Infrastructure/chemistry.JPG";
import physics1a from "../../../assets/img/Infrastructure/physics1a.JPG";
import physics11 from "../../../assets/img/Infrastructure/physics11.JPG";
import bio1 from "../../../assets/img/Infrastructure/bio1.jpg";
import bio2 from "../../../assets/img/Infrastructure/bio2.JPG";
import cs1 from "../../../assets/img/Infrastructure/cs1.JPG";
import cs2 from "../../../assets/img/Infrastructure/cs2.JPG";

const Lab = () => {
  return (
    <div>
      <NavBar />
      <section id="breadcrumbs" class="breadcrumbs">
        <div class="container">
          <ol>
            <li>Home</li>
            <li>Infrastructure</li>
          </ol>
          <h2> Laboratories</h2>
        </div>
      </section>

      <section id="Infrastructure" class="Infrastructure">
        <div class="container">
          <div class="row infra-lab">
            {/* <header class="section-header">
              <Card className="text-center">
                <Card.Header><h4>SCIENCE LABORATORIES</h4></Card.Header>  
              </Card>
              </header> */}
            <h2>SCIENCE LABORATORIES</h2>
            <br />
            <br />
            <p>
              {" "}
              Sanganabasava Residential PU college of science and commerce is
              well equipped with state of the art laboratories wherein the
              students gain a meaningful coherence between theory and practical
              sessions.
            </p>
          </div>
        </div>
      </section>

      <section id="Infrastructure" class="Infrastructure">
        <div class="container">
          <div class="row">
            <header class="section-header">
              <Card className="text-center">
                <Card.Header>
                  <h4>CHEMISTRY LABORATORY</h4>
                </Card.Header>
              </Card>
            </header>
            <p>
              A place that develops inmate futuristic atmosphere. The labs are
              well equipped and can accommodate 20 students in a batch. Well
              qualified and skilled staff empowers the students to respond with
              maximum velocity.
            </p>

            <div class="col-lg-6">
              <div class="post-box">
                <div class="post-img">
                  <img src={chemistry1} class="img-fluid center" alt="" />
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="post-box">
                <div class="post-img">
                  <img src={chemistry} class="img-fluid center" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="Infrastructure" class="Infrastructure">
        <div class="container">
          <div class="row">
            <header class="section-header">
              <Card className="text-center">
                <Card.Header>
                  <h4>PHYSICS LABORATORY</h4>
                </Card.Header>
              </Card>
            </header>
            <p>
              A well equipped lab, spacious enough to accommodate about 20
              students in a batch. An excellent training around, for problem
              solving practical understudies of the subject through experiments
              taught by experienced expert teachers.
            </p>

            <div class="col-lg-6">
              <div class="post-box">
                <div class="post-img">
                  <img src={physics1a} class="img-fluid center" alt="" />
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="post-box">
                <div class="post-img">
                  <img src={physics11} class="img-fluid center" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="Infrastructure" class="Infrastructure">
        <div class="container">
          <div class="row">
            <header class="section-header">
              <Card className="text-center">
                <Card.Header>
                  <h4>BIOLOGY LABORATORY</h4>
                </Card.Header>
              </Card>
            </header>
            <p>
              The lab is well maintained with bio-visual aids, models, slides,
              optical instruments which provides students with a better
              understanding of basic biological concepts.
            </p>
            <div class="col-lg-6">
              <div class="post-box">
                <div class="post-img">
                  <img src={bio1} class="img-fluid center" alt="" />
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="post-box">
                <div class="post-img">
                  <img src={bio2} class="img-fluid center" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="Infrastructure" class="Infrastructure">
        <div class="container">
          <div class="row">
            <header class="section-header">
              <Card className="text-center">
                <Card.Header>
                  <h4>COMPUTER SCIENCE LABORATORY</h4>
                </Card.Header>
              </Card>
            </header>
            <p>
              The college has an efficient computer lab to cater to the needs of
              the ever growing sophistication in the information technology with
              internet, Wi-Fi.
            </p>
            <div class="col-lg-6">
              <div class="post-box">
                <div class="post-img">
                  <img src={cs1} class="img-fluid center" alt="" />
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="post-box">
                <div class="post-img">
                  <img src={cs2} class="img-fluid center" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <Floats />
    </div>
  );
};
export default Lab;
