import React from "react";
import { Link } from "react-router-dom";
import DR_RABINCHANDRA from "../../../assets/img/Staffs/ScienceStaffs/DR_RABINCHANDRA.JPG";
import POOJA_PATIL from "../../../assets/img/Staffs/CommerceStaffs/POOJA_PATIL.JPG";
import FAREEDA_UKKALI from "../../../assets/img/Staffs/CommerceStaffs/FAREEDA_UKKALI.JPG";
import KIRANKUMAR_B_G from "../../../assets/img/Staffs/CommerceStaffs/KIRANKUMAR_B_G.JPG";
import JAYALAXMI from "../../../assets/img/Staffs/CommerceStaffs/JAYALAXMI.JPG";
import SHRIKANT_JALIHAL from "../../../assets/img/Staffs/CommerceStaffs/SHRIKANT_JALIHAL.JPG";
import KAVERI_BAGALKOT from "../../../assets/img/Staffs/CommerceStaffs/KAVERI_BAGALKOT.JPG";
import PRAKASH from "../../../assets/img/Staffs/CommerceStaffs/PRAKASH.JPG";
import PAVAN_HIREMATH from "../../../assets/img/Staffs/CommerceStaffs/PAVAN_HIREMATH.JPG";
import SAVITRI_KOMSHIRASANGI from "../../../assets/img/Staffs/CommerceStaffs/SAVITRI_KOMSHIRASANGI.JPG";

import BASAVARAJ from "../../../assets/img/Staffs/ScienceStaffs/BASAVARAJ.JPG";
import DANAMMA from "../../../assets/img/Staffs/ScienceStaffs/DANAMMA.JPG";
import K_T_NAGARAJ from "../../../assets/img/Staffs/ScienceStaffs/K_T_NAGARAJ.JPG";
import MAHANTESH from "../../../assets/img/Staffs/ScienceStaffs/MAHANTESH.JPG";
import MAHESH from "../../../assets/img/Staffs/ScienceStaffs/MAHESH.JPG";
import MALLAPPA from "../../../assets/img/Staffs/ScienceStaffs/MALLAPPA.JPG";
import NUZHAT from "../../../assets/img/Staffs/ScienceStaffs/NUZHAT.JPG";
import PAAPI_REDDY from "../../../assets/img/Staffs/ScienceStaffs/PAAPI_REDDY.jpg";
import PRASHANT from "../../../assets/img/Staffs/ScienceStaffs/PRASHANT.JPG";
import S_S_SALOTAGI from "../../../assets/img/Staffs/ScienceStaffs/S_S_SALOTAGI.JPG";
import SHIVAYOGI from "../../../assets/img/Staffs/ScienceStaffs/SHIVAYOGI.JPG";
import SEEMA from "../../../assets/img/Staffs/ScienceStaffs/SEEMA.jpg";
import SHASHIKUMAR from "../../../assets/img/Staffs/ScienceStaffs/SHASHIKUMAR.JPG";
import SARASWATI from "../../../assets/img/Staffs/ScienceStaffs/SARASWATI.JPG";
import SARJI_RAO from "../../../assets/img/Staffs/ScienceStaffs/SARJI_RAO.JPG";
import SIDDANAGOUDA from "../../../assets/img/Staffs/ScienceStaffs/SIDDANAGOUDA.JPG";
import SHARANAMMA from "../../../assets/img/Staffs/ScienceStaffs/SHARANAMMA.JPG";
import RAMACHANDRA from "../../../assets/img/Staffs/ScienceStaffs/RAMACHANDRA.JPG";
import MANZOOR from "../../../assets/img/Staffs/ScienceStaffs/MANZOOR.JPG";
import Floats from "../../home/home components/Floats";
import Footer from "../../home/home components/Footer";
import NavBar from "../../home/home components/NavBar";

const SRPU = () => {
  return (
    <div>
      <NavBar />
      <section id="breadcrumbs" class="breadcrumbs">
        <div class="container">
          <ol>
            <li>
              <Link to={"/"}>Home</Link>
            </li>
            <li>About Us</li>
          </ol>
          <h2>SRPU Experience</h2>
        </div>
      </section>

      <section id="Staffs" class="Staffs">
        {/* <div class="container" > */}
        <div class="row ">
          <header class="section-header">
            <h1>SCIENCE DEPARTMENT</h1>
            <p class="heading">PRINICIPAL</p>
          </header>
          <div class="col-lg-4">
            <div class="post-box">
              <div class="post-img">
                <img src={DR_RABINCHANDRA} class="img-fluid center" alt="" />
              </div>
              <h3 class="post-title">DR.RABIN CHANDRA PARAMANIK </h3>
              <span class="post-date">
                M.Sc,M.Phil,Ph.D,F.S.Sc,F.S.E.Sc,F.I.S.E.C,
                F.I.S.B.T.F.I.S.C.A.,D.Litt(U.S.A),(D.Sc).,P.M.I.R&L.W
              </span>
              <span>BIOLOGY</span>
            </div>
          </div>
          <div className="col-md-6">
            <h4>Principal’s message:</h4>
            <br />
            <p className="text-muted-quote">
              <strong>
                {" "}
                “ Education is the most powerful weapon which you can use to
                change the World ” - Nelson Mandela.
              </strong>
              <br />
              <strong>
                {" "}
                “ Where Dreams take wings and aspirations are elevated to a
                status of reality ”
              </strong>
              <br />
              <strong>
                {" "}
                " Transforming ransforming the insitutional mission to the
                mission / fruit of success."
              </strong>
              <br />
            </p>
            <p className="text-muted-message">
              Is there a difference between Land and Nation? House and Home? Boy
              and Son? Yes! There is difference between PU College and
              Sanganabasava Residential PU College, Kavalagi, Vijayapur. Aren’t
              we proud of our nation for UNITY IN DIVERSITY? We enjoy numerous
              festivals, celebrations, cultural occasions, spirituality & our
              family everything that emotionally unite us to experience peace
              and happiness. No other country is blessed with the culture we
              Indians have. But LOOK “What is TV showing our children?”, “What
              do they do on internet?” What do you expect him to learn? And what
              do you think they will actually learn? What is our Future? Along
              with International Standard Educational services, Sanganabasava
              Residential PU College, Kavalagi, Vijayapur. Answers all these
              questions with its dedicated efforts in inculcating Moral Values &
              Spirituality along with world class Education in its students -{" "}
              <strong>Your Child. You’re FUTURE!</strong>
            </p>
          </div>
        </div>

        {/* </div> */}
      </section>
      <section id="Staffs" class="Staffs">
        <div class="container">
          <div class="row">
            <header class="section-header">
              <p class="heading"> PCMB - LECTURERS</p>
            </header>
            <div class="col-lg-4">
              <div class="post-box">
                <div class="post-img">
                  <img src={NUZHAT} class="img-fluid center" alt="" />
                </div>
                <h3 class="post-title">MS. Nuzhat Kazi </h3>
                <span class="post-date fas fa-graduation-cap">
                  {" "}
                  M.Sc. , B.Ed
                </span>
                <span>BIOLOGY</span>
              </div>
            </div>

            <div class="col-lg-4">
              <div class="post-box">
                <div class="post-img">
                  <img src={DANAMMA} class="img-fluid center" alt="" />
                </div>
                <h3 class="post-title">MS. Danamma Mangalwade </h3>
                <span class="post-date fas fa-graduation-cap">
                  {" "}
                  M.Sc. , B.Ed
                </span>
                <span>BIOLOGY</span>
              </div>
            </div>

            <div class="col-lg-4">
              <div class="post-box">
                <div class="post-img">
                  <img src={SEEMA} class="img-fluid center" alt="" />
                </div>
                <h3 class="post-title">MS. Seema </h3>
                <span class="post-date fas fa-graduation-cap">
                  {" "}
                  M.Sc. , B.Ed
                </span>
                <span>BIOLOGY</span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="Staffs" class="Staffs">
        <div class="container">
          <div class="row">
            <header class="section-header">
              <p class="heading">CHEMISTRY - LECTURERS</p>
            </header>
            <div class="col-lg-4">
              <div class="post-box">
                <div class="post-img">
                  <img src={S_S_SALOTAGI} class="img-fluid center" alt="" />
                </div>
                <h3 class="post-title">MR. Shivanand Salotagi </h3>
                <span class="post-date fas fa-graduation-cap">
                  {" "}
                  M.Sc. , B.Ed
                </span>
                <span>CHEMISTRY</span>
              </div>
            </div>

            <div class="col-lg-4">
              <div class="post-box">
                <div class="post-img">
                  <img src={SHIVAYOGI} class="img-fluid center" alt="" />
                </div>
                <h3 class="post-title">MR. Shivayogi </h3>
                <span class="post-date fas fa-graduation-cap">
                  {" "}
                  M.Sc. , B.Ed
                </span>
                <span>CHEMISTRY</span>
              </div>
            </div>

            <div class="col-lg-4">
              <div class="post-box">
                <div class="post-img">
                  <img src={MAHESH} class="img-fluid center" alt="" />
                </div>
                <h3 class="post-title">MR. Mahesh Gurav </h3>
                <span class="post-date fas fa-graduation-cap">
                  {" "}
                  M.Sc. , B.Ed
                </span>
                <span>CHEMISTRY</span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="Staffs" class="Staffs">
        <div class="container">
          <div class="row">
            <header class="section-header">
              <p class="heading">PCMC - LECTURERS</p>
            </header>
            <div class="col-lg-4">
              <div class="post-box">
                <div class="post-img">
                  <img src={PRASHANT} class="img-fluid center" alt="" />
                </div>
                <h3 class="post-title">MR. Prashant Patil</h3>
                <span class="post-date fas fa-graduation-cap"> B.E. , CS</span>
                <span>COMPUTER SCIENCE</span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="Staffs" class="Staffs">
        <div class="container">
          <div class="row">
            <header class="section-header">
              <p class="heading">LANGUAGES - LECTURERS</p>
            </header>
            <div class="col-lg-4">
              <div class="post-box">
                <div class="post-img">
                  <img src={MALLAPPA} class="img-fluid center" alt="" />
                </div>
                <h3 class="post-title">MR. Mallappa Jaggal </h3>
                <span class="post-date fas fa-graduation-cap">
                  {" "}
                  M.A. , B.Ed
                </span>
                <span>KANNADA - LECTURER</span>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="post-box">
                <div class="post-img">
                  <img src={MAHANTESH} class="img-fluid center" alt="" />
                </div>
                <h3 class="post-title">MR. Mahantesh </h3>
                <span class="post-date fas fa-graduation-cap"> M.A , B.Ed</span>
                <span>ENGLISH - LECTURER</span>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="post-box">
                <div class="post-img">
                  <img src={SHASHIKUMAR} class="img-fluid center" alt="" />
                </div>
                <h3 class="post-title">MR. Shashikumar </h3>
                <span class="post-date fas fa-graduation-cap"> M.A , B.Ed</span>
                <span>HINDI - LECTURER</span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="Staffs" class="Staffs">
        <div class="container">
          <div class="row">
            <header class="section-header">
              <p class="heading">LIBRARIAN</p>
            </header>
            <div class="col-lg-4">
              <div class="post-box">
                <div class="post-img">
                  <img src={SARASWATI} class="img-fluid center" alt="" />
                </div>
                <h3 class="post-title">MS. Saraswati Madar</h3>
                <span class="post-date fas fa-graduation-cap"> M.Lib</span>
                <span>LIBRARY</span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="Staffs" class="Staffs">
        <div class="container">
          <div class="row">
            <header class="section-header">
              <p class="heading">MATHEMATICS - LECTURERS</p>
            </header>
            <div class="col-lg-4">
              <div class="post-box">
                <div class="post-img">
                  <img src={BASAVARAJ} class="img-fluid center" alt="" />
                </div>
                <h3 class="post-title">MR. Basavraj Jakapure </h3>
                <span class="post-date fas fa-graduation-cap">
                  {" "}
                  M.Sc. , B.Ed
                </span>
                <span>MATHEMATICS</span>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="post-box">
                <div class="post-img">
                  <img src={K_T_NAGARAJ} class="img-fluid center" alt="" />
                </div>
                <h3 class="post-title">MR. K.T.Nagaraj </h3>
                <span class="post-date fas fa-graduation-cap">
                  {" "}
                  M.Sc. , B.Ed
                </span>
                <span>MATHEMATICS</span>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="post-box">
                <div class="post-img">
                  <img src={PAAPI_REDDY} class="img-fluid center" alt="" />
                </div>
                <h3 class="post-title">MR. Papi Reddy </h3>
                <span class="post-date fas fa-graduation-cap">
                  {" "}
                  M.Sc. , B.Ed
                </span>
                <span>MATHEMATICS</span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="Staffs" class="Staffs">
        <div class="container">
          <div class="row">
            <header class="section-header">
              <p class="heading">PHYSICAL EDUCATION</p>
            </header>
            <div class="col-lg-4">
              <div class="post-box">
                <div class="post-img">
                  <img src={SARJI_RAO} class="img-fluid center" alt="" />
                </div>
                <h3 class="post-title">MR. Sarajiroa Chavan</h3>
                <span class="post-date fas fa-graduation-cap"> M.P.Ed</span>
                <span>PHYSICAL EDUACATION</span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="Staffs" class="Staffs">
        <div class="container">
          <div class="row">
            <header class="section-header">
              <p class="heading">PHYSICS - LECTURERS</p>
            </header>
            <div class="col-lg-4">
              <div class="post-box">
                <div class="post-img">
                  <img src={SIDDANAGOUDA} class="img-fluid center" alt="" />
                </div>
                <h3 class="post-title">MR. Siddanagouda.R.T </h3>
                <span class="post-date fas fa-graduation-cap">
                  {" "}
                  M.Sc. , B.Ed
                </span>
                <span>PHYSICS</span>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="post-box">
                <div class="post-img">
                  <img src={SHARANAMMA} class="img-fluid center" alt="" />
                </div>
                <h3 class="post-title">MS. Sharanamma.H </h3>
                <span class="post-date fas fa-graduation-cap">
                  {" "}
                  M.Sc. , B.Ed
                </span>
                <span>PHYSICS</span>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="post-box">
                <div class="post-img">
                  <img src={RAMACHANDRA} class="img-fluid center" alt="" />
                </div>
                <h3 class="post-title">MR. Ramachandra </h3>
                <span class="post-date fas fa-graduation-cap">
                  {" "}
                  M.Sc. , B.Ed
                </span>
                <span>PHYSICS</span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="Staffs" class="Staffs">
        <div class="container">
          <div class="row">
            <header class="section-header">
              <p class="heading">ADMINISTRATIVE OFFICER</p>
            </header>
            <div class="col-lg-4">
              <div class="post-box">
                <div class="post-img">
                  <img src={MANZOOR} class="img-fluid center" alt="" />
                </div>
                <h3 class="post-title">MR. Manzoor Wahidi</h3>
                <span class="post-date fas fa-graduation-cap"> MBA</span>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="Staffs" class="Staffs">
        <div class="container">
          <div class="row">
            <header class="section-header">
              <h1>COMMERCE DEPARTMENT</h1>
              <p class="heading">VICE-PRINICIPAL</p>
            </header>
            <div class="col-lg-4">
              <div class="post-box">
                <div class="post-img">
                  <img src={DR_RABINCHANDRA} class="img-fluid center" alt="" />
                </div>
                <h3 class="post-title">MR. VEERESH P </h3>
                <span class="post-date fas fa-graduation-cap"> MBA</span>
                {/* <span>Vice-Principal</span> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="Staffs" class="Staffs">
        <div class="container">
          <div class="row">
            <header class="section-header">
              <p class="heading">ACCOUNTS - LECTURER</p>
            </header>
            <div class="col-lg-4">
              <div class="post-box">
                <div class="post-img">
                  <img src={POOJA_PATIL} class="img-fluid center" alt="" />
                </div>
                <h3 class="post-title">Mrs. Pooja Patil </h3>
                <span class="post-date fas fa-graduation-cap">
                  {" "}
                  M.COM , B.ED
                </span>
                <span>Asst Prof Account</span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="Staffs" class="Staffs">
        <div class="container">
          <div class="row">
            <header class="section-header">
              <p class="heading">ECONOMICS - LECTURER</p>
            </header>
            <div class="col-lg-4">
              <div class="post-box">
                <div class="post-img">
                  <img src={FAREEDA_UKKALI} class="img-fluid center" alt="" />
                </div>
                <h3 class="post-title">Mrs. Farida Ukkali </h3>
                <span class="post-date fas fa-graduation-cap"> M.A , B.ED</span>
                <span>Asst Prof Economics</span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="Staffs" class="Staffs">
        <div class="container">
          <div class="row">
            <header class="section-header">
              <p class="heading">COMMERCE - LECTURERS</p>
            </header>
            <div class="col-lg-4">
              <div class="post-box">
                <div class="post-img">
                  <img src={PAVAN_HIREMATH} class="img-fluid center" alt="" />
                </div>
                <h3 class="post-title">Mr. Pavankumar Hiremath </h3>
                <span class="post-date fas fa-graduation-cap">
                  {" "}
                  M.COM , B.ED , MBA
                </span>
                <span>Asst Prof Commerce</span>
              </div>
            </div>

            <div class="col-lg-4">
              <div class="post-box">
                <div class="post-img">
                  <img src={POOJA_PATIL} class="img-fluid center" alt="" />
                </div>
                <h3 class="post-title">Miss. Sujata Chavan</h3>
                <span class="post-date fas fa-graduation-cap"> M.COM</span>
                <span>Asst Prof Commerce</span>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="post-box">
                <div class="post-img">
                  <img src={POOJA_PATIL} class="img-fluid center" alt="" />
                </div>
                <h3 class="post-title">Mrs. Ramya .C.R</h3>
                <span class="post-date fas fa-graduation-cap"> M.A , B.ED</span>
                <span>Asst Prof Commerce</span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="Staffs" class="Staffs">
        <div class="container">
          <div class="row">
            <header class="section-header">
              <p class="heading">COMPUTER - LECTURER </p>
            </header>
            <div class="col-lg-4">
              <div class="post-box">
                <div class="post-img">
                  <img src={KIRANKUMAR_B_G} class="img-fluid center" alt="" />
                </div>
                <h3 class="post-title">Mr. Kirankumar.B.G </h3>
                <span class="post-date fas fa-graduation-cap">
                  {" "}
                  B.E , M.TECH
                </span>
                <span>Asst Prof Computer</span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="Staffs" class="Staffs">
        <div class="container">
          <div class="row">
            <header class="section-header">
              <p class="heading">LANGUAGES - LECTURERS</p>
            </header>
            <div class="col-lg-4">
              <div class="post-box">
                <div class="post-img">
                  <img
                    src={SAVITRI_KOMSHIRASANGI}
                    class="img-fluid center"
                    alt=""
                  />
                </div>
                <h3 class="post-title">Miss. SAVITRI KOMSHIRASANGI </h3>
                <span class="post-date fas fa-graduation-cap"> M.A , B.ED</span>
                <span>Asst Prof  Kannada</span>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="post-box">
                <div class="post-img">
                  <img src={JAYALAXMI} class="img-fluid center" alt="" />
                </div>
                <h3 class="post-title">Mrs. Jayalaxmi Anawal</h3>
                <span class="post-date fas fa-graduation-cap">
                  {" "}
                  M.A , B.Ed , M.Ed
                </span>
                <span>Asst Prof English</span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="Staffs" class="Staffs">
        <div class="container">
          <div class="row">
            <header class="section-header">
              <p class="heading">NON - TEACHING STAFFS</p>
            </header>
            <div class="col-lg-4">
              <div class="post-box">
                <div class="post-img">
                  <img src={SHRIKANT_JALIHAL} class="img-fluid center" alt="" />
                </div>
                <h3 class="post-title">Mr. Shrikant Jalihal</h3>
                <span class="post-date fas fa-graduation-cap"> B.SC</span>
                <span>Fda, Accountant</span>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="post-box">
                <div class="post-img">
                  <img src={KAVERI_BAGALKOT} class="img-fluid center" alt="" />
                </div>
                <h3 class="post-title">Mrs. Kaveri Bagalkot</h3>
                <span class="post-date fas fa-graduation-cap "> PUC</span>
                <span>Attender</span>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="post-box">
                <div class="post-img">
                  <img src={PRAKASH} class="img-fluid center" alt="" />
                </div>
                <h3 class="post-title">Prakash </h3>
                <span class="post-date fas fa-graduation-cap "> SSLC</span>
                <span>Attender</span>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
      <Floats />
    </div>
  );
};
export default SRPU;
