import Recat from "react";
import { Link } from "react-router-dom";
import yatnal from "../../../assets/img/yatnal.jpg";
import Floats from "../../home/home components/Floats";
import Footer from "../../home/home components/Footer";
import NavBar from "../../home/home components/NavBar";

const PresidentMessage = () => {
  return (
    <div>
      <NavBar />
      <section id="breadcrumbs" class="breadcrumbs">
        <div class="container">
          <ol>
            <li>
              <Link to={"/"}>Home</Link>
            </li>
            <li>About Us</li>
          </ol>
          <h2>President's Message</h2>
        </div>
      </section>
      {/* <!-- End Breadcrumbs --> */}

      <section id="Staffs" class="Staffs">
        <div class="row ">
          <div class="col-lg-4">
            <div class="post-box">
              <div class="post-img">
                <img src={yatnal} class="img-fluid center" alt="" />
              </div>
              <h5 class="post-title">Shri. Basanagouda R. Patil (Yatnal)</h5>
              <span class="post-date">
                <strong>
                  Former Central Minister of Railways
                  <br />
                  Member of Legislative Assembly
                </strong>
              </span>
            </div>
          </div>

          <div class="col-lg-8 ">
            <div class="post-box">
              <h5 class="post-title">President's Message:</h5>
              Founder President / Chairman of Sanganabasava International
              Residential School and PU College of Science and Commerce, Kavalgi
              Post Honnutagi District, Vijayapura, Karnataka – 586127, is driven
              by the purpose to put quality education within the reach of all,
              which will help young minds to grow and develop into progressive
              thinkers and leaders of tomorrow. <br />
              <br />
              With rich experience, passion for creating the best and a strong
              focus towards a greater goal, Shri. Basanagouda R. Patil (Yatnal),
              Member of Legislative Assembly, Vijayapur has built one of the
              most renowned brands in the education sector. Sir is a well-known
              edupreneur and is regarded for his visionary leadership in the
              education sector. <br />
              <br />
              He is always acknowledged as a person who is a champion of “Good
              Thoughts, Good Words, and Good Deeds”. SIRS & PU College is
              renowned to nurture freethinking, socially responsible students,
              in an active and free atmosphere, to make students truly world
              ready.
            </div>
          </div>
        </div>
      </section>

      <section id="Staffs" class="Staffs">
        <div class="row ">
          <div class="col-lg-12">
            <div class="post-box">
              A born leader and a motivator, Sir is an inspiration to all as he
              spearheads SIRS group of Schools and PU College management and
              operations, strategizing and directing it through its next phase
              of growth. His business leadership has been recognized by several
              corporate and community organizations. Sir has been associated
              with SIRS since its inception and continues to contribute in its
              progress. Sir has been an integral part of the Founder Member team
              and continues to contribute to the growth of the Organization
              through his wide experience and counsel. Here wishing each one of
              you a great future. <br />
              <br />
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <Floats />
    </div>
  );
};

export default PresidentMessage;
