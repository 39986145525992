import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Floats from "../../home/home components/Floats";
import Footer from "../../home/home components/Footer";
import NavBar from "../../home/home components/NavBar";
import Moment from "moment";

import {
  Card,
  CardHeader,
  CardGroup,
  CardImg,
  CardBody,
  CardTitle,
  CardText,
  Button,
  Navbar,
} from "react-bootstrap";
import axios from "axios";

const Notification = () => {
  const [notification, setNotification] = useState([]);
  const [notificationId, setNotificationId] = useState("");
  const [notificationHeader, setNotificationHeader] = useState("");
  const [notificationDate, setNotificationDate] = useState("");
  const [description, setDescription] = useState("");
  const [file, setFile] = useState("");
  const [filePath, setFilePath] = useState("");

  const [onEdit, setOnEdit] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [value, setValue] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  const user = JSON.parse(sessionStorage.getItem("user"));
  const headers = {
    "Content-type": "application/json",
    Authorization:
      "Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJhZG1pbjEiLCJpYXQiOjE2NDgwMzE5OTUsImV4cCI6MTY2MzU4Mzk5NX0.h3uex4gcdMAA8cOMN_mqFbxlYhZRtFkQMLoke7y-8dXL0-oBy41qmWhkIJ-O0bNlaLcdHYxKygm3GHdd84bMVw",
  };

  const BarStyling = {
    width: "20rem",
    background: "#F2F1F9",
    border: "#0d6efd",
    padding: "0.5rem",
    marginBottom: 10,
  };

  const FileUrl = `https://executivetracking.cloudjiffy.net/SRPUSchoolWeb/file/downloadFile/?filePath=`;

  const baseUrl = "https://executivetracking.cloudjiffy.net/SRPUSchoolWeb";

  const FetchData = async () => {
    await axios({
      method: "get",
      url: `${baseUrl}/notification/v1/getAllNotificationByPagination/{pageNumber}/{pageSize}?pageNumber=${pageNumber}&pageSize=${pageSize}`,
      headers,
      body: JSON.stringify(),
    })
      .then((res) => {
        let data = res.data;
        setPageCount(data.totalPages);
        setNotification(data.content);
        console.log(data.content);
      })

      .catch(function (error) {
        console.log(error);
      });
  };

  const handlePageClick = (data) => {
    setPageNumber(data.selected);
    FetchData(pageNumber);
  };
  const handleChange = (e) => {
    setPageSize(e.target.value);
    setValue(e.target.value);
    FetchData(pageSize);
  };

  useEffect(() => {
    FetchData();
  }, [
    notificationId,
    notificationHeader,
    notificationDate,
    description,
    file,
    filePath,
    pageSize,
    pageNumber,
  ]);

  return (
    <div>
      <NavBar />
      {/* <!------- End of Breadcrumbs Section -------> */}
      <section id="breadcrumbs" class="breadcrumbs">
        <div class="container">
          <ol>
            <li>
              <Link to={"/"}>Home</Link>
            </li>
            <li>Notification</li>
          </ol>
          <h2>Notification</h2>
        </div>
      </section>
      {/* <!------- End of Breadcrumbs Section -------> */}

      <section id="portfolio" class="portfolio">
        <div class="container">
          <header class="section-header">
            <Card className="text-center">
              <Card.Header>
                <h4>Notification</h4>
              </Card.Header>
            </Card>
          </header>
        </div>
      </section>

      <div class="container">
        {notification.map((data, index) => {
          return (
            <div class="notification">
              <h5 class="card-header">{data.notificationHeader}</h5>
              <div class="card-body">
                <div class="row">
                  <div class="col-8 card-text">{data.description}</div>
                </div>
              </div>

              <div class="card-body">
                <Button onClick={(e) => window.open(FileUrl + data.filePath)}>
                  Download
                </Button>
                <Button style={{ marginLeft: 20, backgroundColor: "#1E90FF" }}>
                  {Moment(data.notificationDate).format("DD-MM-YYYY")}
                </Button>
              </div>
              <hr />
            </div>
          );
        })}
      </div>
      <Footer />
      <Floats />
    </div>
  );
};

export default Notification;
