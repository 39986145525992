import React from "react";
import { Link } from "react-router-dom";
import CAO from "../../../assets/img/CAO.JPG";
import Floats from "../../home/home components/Floats";
import Footer from "../../home/home components/Footer";
import NavBar from "../../home/home components/NavBar";

const CAOMessage = () => {
  return (
    <div>
      <NavBar />
      <section id="breadcrumbs" class="breadcrumbs">
        <div class="container">
          <ol>
            <li>
              <Link to={"/"}>Home</Link>
            </li>
            <li>About Us</li>
          </ol>
          <h2>CAO's Message</h2>
        </div>
      </section>
      {/* <!-- End Breadcrumbs --> */}

      <section id="Staffs" class="Staffs">
        <div class="row ">
          <div class="col-lg-4">
            <div class="post-box">
              <div class="post-img">
                <img src={CAO} class="img-fluid center" alt="" />
              </div>
              <h5 class="post-title">Dr. H. VENKATESH </h5>
              <span class="post-date">
                <strong>
                  CAO, <br />
                  M.Sc., M.Phill., Ph.D CAO, Academics., <br />
                  Syndicate Member, Karnataka state Akkamahadevi Womens
                  University,Vijayapura., <br />
                  Former Deputy Registrar and Special Officer RCU, Belgaum,
                  Karnataka.
                </strong>
              </span>
            </div>
          </div>

          <div class="col-lg-8 ">
            <div class="post-box">
              <h5 class="post-title">CAO's Message :</h5>
              “Nothing great in the world has ever been accomplished without
              passion.” — G. W. F. Hegel A trailblazer in educational
              innovation.
              <br /> <br />
              DR H VENKATESH has been an educationist for over two decades. He
              believes that education can be the catalyst to transform the
              world, inspire the young generation and make the world a better
              place. A powerful driving force behind SIRS Group of Schools and
              PU College since its inception, an academician in him felt that
              the curriculum offered in a large number of schools did not equip
              children for the demands of the future. <br />
              <br />
              He led a team of experts who designed a curriculum that has a fine
              mix of global teaching practices and the Indian curriculum. He is
              someone who strives for change and is constantly working on new
              initiatives for the overall development of children to provide an
              admirable learning experience for our young learners to be
              acquainted with global issues to provide enhanced learning
              experience to students as well as broaden the scope of offerings.{" "}
              <br />
              <br />
            </div>
          </div>
        </div>
      </section>

      <section id="Staffs" class="Staffs">
        <div class="row ">
          <div class="col-lg-12">
            <div class="post-box">
              His tireless work and exemplary contribution to the field of
              education has won him many accolades. He provides vision and
              strategic direction to the Institutions he collaborates with. Sir
              has always been passionate about education & believes in unique
              approach to education, which leads to newer heights of excellence
              in molding the young minds of tomorrow. <br />
              <br />
              He maintains that Education is a dedicated service; hence focus
              and involvement are extremely necessary and crucial, as knowledge
              is limitless. He believes in hard work and taking up challenging
              jobs to scale new heights.
              <br />
              <br />
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <Floats />
    </div>
  );
};

export default CAOMessage;
