import React from 'react';
import NavBar from './NavBar';
import Footer from './Footer';
import LandingHome from './LandingHome';
import AboutHome from './AboutHome';
import Portfolio from './Portfolio';
import ContactHome from './ContactHome';
import Floats from './Floats';
import TopRankers from './TopRankers';
import News from './News';

const HomePage =() => {
    return (
      <div>
        <NavBar />
        <LandingHome />
        <News />
        <AboutHome />
        <TopRankers />
        <Portfolio />
        {/* <ContactHome/> */}
        <Floats />
        <Footer />
      </div>
    );
}

export default HomePage;