import React from "react";
import { Link } from "react-router-dom";
import { Card, CardHeader } from "react-bootstrap";
import Floats from "../../home/home components/Floats";
import Footer from "../../home/home components/Footer";
import NavBar from "../../home/home components/NavBar";
import library from "../../../assets/img/Infrastructure/library.JPG";
import LIBRARY2 from "../../../assets/img/Infrastructure/LIBRARY2.JPG";

const Libraries = () => {
  return (
    <div>
      <NavBar />
      {/* <!------- Breadcrumbs Section -------> */}
      <section id="breadcrumbs" class="breadcrumbs">
        <div class="container">
          <ol>
            <li>
              <Link to={"/"}>Home</Link>
            </li>
            <li>Infrastructure</li>
          </ol>
          <h2>LIBRARIES</h2>
        </div>
      </section>
      {/* <!------- End of Breadcrumbs Section -------> */}

      <section id="Infrastructure" class="Infrastructure">
        <div class="container">
          <div class="row">
            <header class="section-header">
              <Card className="text-center">
                <Card.Header>
                  <h4>LIBRARIES</h4>
                </Card.Header>
              </Card>
            </header>
            <p>
              The college has one of the best equipped libraries. It is spacious
              enough to accommodate 50 students at a time with an exhaustive
              collection of books, magazines, Journals, newspapers, e-journals
              etc. Library is an important learning resource centre with open
              access systems encouraging the user to browse freely in the tack
              area. The library provides the information and reference in the
              academic programs and innovations in different fields. There are
              also different setup that provides materials insight and
              information and personality. Students can also access to
              university Question Papers and Question Banks.
            </p>
            <div class="col-lg-6">
              <div class="post-box">
                <div class="post-img">
                  <img src={library} class="img-fluid center" alt="" />
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="post-box">
                <div class="post-img">
                  <img src={LIBRARY2} class="img-fluid center" alt="" />
                </div>
              </div>
            </div>
            <div></div>
          </div>
        </div>
      </section>

      <Footer />
      <Floats />
    </div>
  );
};
export default Libraries;
