import React from "react";
import logo_header from "../../../assets/img/logo_header.png";

const Footer = () => {
  return (
    <div>
      <footer id="footer" className="footer" >
        <div class="footer-top">
          <div class="container">
            <div class="row gy-4" >
              <div class="col-12 col-md-8 col-sm-8 footer-info" style={{textAlign:"center"}}>
                <a href="index.html" class="logo d-flex align-items-center">
                  <img src={logo_header} alt="" />
                  <h2>
                    Sanganabasava PU College <br />
                  </h2>
                </a>
                <p>
                  {" "}
                  Every child growing to his fullness, physically and
                  intellectually, emotionally and socially, aesthetically and
                  spiritually in an atmosphere of joy, freedom and
                  responsibility and winning his way in life.
                </p>
                <div class="social-links mt-3">
                  <a
                    href="https://www.facebook.com/Sanganabasava-Residential-PU-College-for-Science-and-Commerce-253488476528074"
                    class="facebook"
                  >
                    <i class="fab fa-facebook"></i>
                  </a>
                  <a href="https://twitter.com/srpu_official" class="twitter">
                    <i class="fab fa-twitter"></i>
                  </a>
                  <a
                    href="https://www.youtube.com/channel/UCry3Qk-N6GymfBqv4ZMb7Zw"
                    className="youtube"
                  >
                    <i class="fab fa-youtube"></i>
                  </a>
                  <a
                    href="https://www.instagram.com/srpu_official/"
                    class="instagram"
                  >
                    <i class="fab fa-instagram"></i>
                  </a>
                </div>
              </div>

              {/* <div class="col-4 col-md-4 col-sm-4 footer-links" >
                <h4>Contact Us : </h4>
                <p>
                  Sanganabasava Residential PU College
                   for Science and Commerce, Kavalagi. 
                  Post: Honnutagi 
                  District Vijayapura, 
                  Karnataka – 586127 
                  
                  <h4>
                    <strong>Phone :</strong>
                  </h4>{" "}
                  <p>
                    +91 8660839779
                    <br />
                    +91 9880608303 <br /> 
                    +91 9113281526
                  </p>
                  <h4>
                    <strong>Email :</strong>
                  </h4>{" "}
                  srpucollege.kavalagi@gmail.com
                  <br />
                </p>
              </div> */}
              <div class="col-6col-md-4 col-sm-4 footer-links" style={{textAlign:"center"}}>
                <h4>Contact Us : </h4>
                <p style={{textAlign:"center"}}>

                  Sanganabasava Residential PU College,<br/> 
                  for Science and Commerce, Kavalagi,<br/>
                  District Vijayapura<br/> 
                  Karnataka 586127
                  <br /><br/>
                  <h4>
                    <strong style={{textAlign:"center"}}>Phone :</strong>
                  </h4>{" "}
                  <p style={{textAlign:"center"}}>
                  +91 9880608303
                    <br />
                    +91 9113281526
                  </p>
                  <h4 style={{textAlign:"center"}}>
                    <strong >Email :</strong>
                  </h4>{" "}
                  srpucollege.kavalagi@gmail.com
                  <br />
                </p>
              </div>
              
            </div>
          </div>
        </div>

        {/* Copyright  */}
        <div className="container">
          <div className="copyright">
            &copy; Copyright{" "}
            <strong>
              <span>Sanganabasava Residential PU College</span>
            </strong>
            . All Rights Reserved
          </div>
          <div className="credits">
            Designed by{" "}
            <a href="http://www.walkinsoftwares.com/">
              {" "}
              <span>Walkin Softwares Technologices</span>
            </a>
          </div>
        </div>
      </footer>
      {/* End of Copyright  */}
    </div>
  );
};

export default Footer;
